// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Heading } from '@chakra-ui/react';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { FocusableCardBody } from './FocusableCardBody';

export interface FCBCollectionProps {
  title: string;
  iconType: any;
}

export const FCBCollection = forwardRef<FCBCollectionProps, 'div'>(
  ({ title, iconType }, ref) => {
    return (
      <FocusableCardBody>
        <ReactIcon as={iconType} size="xs" />
        <Heading size="xs">{title}</Heading>
      </FocusableCardBody>
    );
  },
);
