// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';
import { FocusableCardBody } from '../FocusableCardBody';
import { FCBSearchHintKbd } from './FCBSearchHintKbd';
import { FCBSearchHintText } from './FCBSearchHintText';

export interface FCBSearchHintProps {
  title: string;
  description: string;
}

export const FCBSearchHint = forwardRef<FCBSearchHintProps, 'div'>(
  ({ title, description }, ref) => {
    return (
      <FocusableCardBody>
        <FCBSearchHintKbd>{title}</FCBSearchHintKbd>
        <FCBSearchHintText>{description}</FCBSearchHintText>
      </FocusableCardBody>
    );
  },
);
