// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { ApolloError } from '@apollo/client';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { copy } from '~/common/copy/copy';
import { useItemByUrlQuery } from '~/common/gql/item.generated';
import ternaryIf from '~/common/utilities/array-utils/ternaryIf';
import { getItemTitle } from '~/common/utilities/item-utils/item';
import { pageInfo, PageType } from '~/common/utilities/pageInfo';
import { systemTag } from '~/common/utilities/systemTag';
import { isUrlHttpOrHttps } from '~/common/utilities/url-utils/isUrlHttpOrHttps';
import { gotoReader } from '~/containers/pages/ReaderPage/helpers';
import { useItemByUrlQueryHelper } from '~/hooks/useItemByUrlQueryHelper';
import { useItemCreateQueryHelper } from '~/hooks/useItemCreateQueryHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { FCBAddUrlProps } from './FocusableCard/FCBAddUrl';
import { FCBItemProps } from './FocusableCard/FCBItem/FCBItem';
import { FocusableProps } from './FocusableCard/FocusableProps';

interface Props {
  namespace?: string;
  searchTerm: string;
}

interface FocusableURLItemFoundProps extends FocusableProps, FCBItemProps {
  isInLibrary: true;
}

interface FocusableURLItemNotFoundProps extends FocusableProps, FCBAddUrlProps {
  isInLibrary: false;
}

type FocusableResultProps =
  | FocusableURLItemFoundProps
  | FocusableURLItemNotFoundProps;

export const useSearchUrl = ({
  namespace = 'url',
  searchTerm,
}: Props): [ReturnType<typeof useItemByUrlQuery>, FocusableResultProps[]] => {
  const searchIsEmpty = isEmpty(searchTerm?.trim());
  const { renderToastWith } = useToastHelper();
  const [getItemByUrl, result] = useItemByUrlQueryHelper();
  const [createItemMutation] = useItemCreateQueryHelper();

  const debounceSearch = useMemo(
    () =>
      debounce((_searchTerm) => {
        getItemByUrl(_searchTerm);
      }, 600),
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const addBookmark = (bookmarkUrl: string) => {
    // adds bookmark to current context i.e. to do/tag/trash page

    return createItemMutation(
      {
        input: {
          url: bookmarkUrl,
          collectionIds: ternaryIf(
            pageInfo.current.pageType === PageType.Tag &&
              !systemTag.ids.includes(pageInfo.current.tagId),
            [pageInfo.current.tagId],
            [],
          ),
          isTodo: pageInfo.current.pageType === PageType.Todo,
          trashed:
            pageInfo.current.pageType === PageType.Tag &&
            pageInfo.current.tagId == systemTag.trash.id,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.createItemSuccess,
          then: result.refetch,
        }),
        onError: (e) => {
          // TODO: Handle apollo error (graphQLErrors, and all types of networkErrors)
          e as ApolloError;

          const errors =
            e?.graphQLErrors
              ?.map((it) => it.message ?? it.exception?.detailMessage)
              .join('\n') ?? e.message;

          renderToastWith({
            message: errors ?? copy.createItemError,
          })();
        },
      },
    );
  };

  useEffect(() => {
    // update item search every time the search filter changes
    debounceSearch(searchTerm);
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  if (searchIsEmpty) return [result, []];
  if (result.variables.input?.itemUrl !== searchTerm) return [result, []];
  if (result.loading && !result.data) return [result, []];
  if (!isUrlHttpOrHttps(searchTerm)) return [result, []];

  const it = result.data?.itemByUrl;

  if (it) {
    // show item with url matching the current search term
    // if all the above conditions are met i.e.
    // 1. search is not empty
    // 2. data matches the latest search term
    // 3. query is loading and does not have data
    return [
      result,
      [
        {
          key: `${namespace}:${it.id}`,
          title: getItemTitle(result.data.itemByUrl),
          imageSrc: it.urlData?.imageSrc,
          url: it.url,
          note: it.note,
          collections: it.collection?.map((it) => it.title),
          onClick: () => {
            gotoReader({
              openItem: it,
            });
          },
          isInLibrary: true,
        },
      ],
    ];
  }

  // if query ran and there are no url matches
  // to a search term that is a url
  // then allow user to add the url to the current context
  return [
    result,
    [
      {
        key: `${namespace}:${searchTerm}`,
        url: searchTerm,
        onClick: () => {
          addBookmark(searchTerm);
        },
        isInLibrary: false,
      },
    ],
  ];
};
