// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { mainMenuMobileHeight } from '~/common/uiTokens';

export const NoticeStack = forwardRef<FlexProps, 'div'>((props, ref) => {
  return (
    <Flex
      ref={ref}
      gap={2}
      direction="column"
      position="fixed"
      justify="flex-end"
      maxW="md"
      right={0}
      px={3}
      mb={4}
      bottom={[mainMenuMobileHeight, mainMenuMobileHeight, 0]}
      {...props}
    />
  );
});
