// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { useRouter } from 'next/router';
import { pageInfo, PageType } from '~/common/utilities/pageInfo';
import { systemTag } from '~/common/utilities/systemTag';
import { useAppContext } from '~/containers/common/AppContext';

type NavItemType =
  | number
  | 'library'
  | 'search'
  | 'todo'
  | 'all'
  | 'more'
  | 'trash';

export const useAppMenu = () => {
  const router = useRouter();

  const { searchModal } = useAppContext();

  const isCollectionActive = (id: number) => {
    return (
      pageInfo.current.pageTypeWithRouter(router) === PageType.Tag &&
      pageInfo.current.tagIdWithRouter(router) === id
    );
  };

  const isSharedItemActive = (shareKey: string) => {
    return (
      pageInfo.current.pageTypeWithRouter(router) ==
        PageType.SharedCollectionPrefix &&
      pageInfo.current.sharingKeyWithRouter(router) == shareKey
    );
  };

  const isNavItemActive = (id: NavItemType) => {
    switch (id) {
      case 'library':
        return (
          !searchModal.isOpen &&
          [PageType.Tag, PageType.Todo].includes(
            pageInfo.current.pageTypeWithRouter(router),
          )
        );
      case 'search':
        return searchModal.isOpen;
      case 'more':
        return false;
      case 'todo':
        return (
          !searchModal.isOpen &&
          pageInfo.current.pageTypeWithRouter(router) === PageType.Todo
        );
      case 'all':
      case systemTag.all.id:
        return (
          isCollectionActive(systemTag.all.id) ||
          isCollectionActive(systemTag.orphan.id)
        );
      case 'trash':
      case systemTag.trash.id:
        return isCollectionActive(systemTag.trash.id);
      default:
        return isCollectionActive(id);
    }
  };

  return {
    isNavItemActive,
    isSharedItemActive,
  };
};
