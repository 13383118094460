// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const WideCardMetadataCell = forwardRef<Props, 'div'>((props, ref) => {
  return (
    <Flex
      ref={ref}
      flex="0 0"
      w={['auto', 'auto', 'auto', '240px']}
      minW={['auto', 'auto', 'auto', '240px']}
      maxW={['auto', 'auto', 'auto', '240px']}
      direction="column"
      gap={2}
      {...props}
    />
  );
});
