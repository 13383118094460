// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { FC } from 'react';
import { Section } from '~/common/gql';
import { useDisclosureContext } from '../../App/LeftSidebar/LeftSidebarSection/DisclosureContext/useDisclosureContext';
import { ModalSectionDelete } from './ModalSectionDelete';

interface Props {
  section: Pick<Section, 'id' | 'title'>;
}

export const ModalSectionDeleteDisclosureContext: FC<Props> = ({
  section,
}: Props) => {
  const { isOpen, onClose } = useDisclosureContext();
  return (
    <ModalSectionDelete section={section} isOpen={isOpen} onClose={onClose} />
  );
};
