// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { NextRouter } from 'next/router';
import { systemTag } from './systemTag';

export enum PageType {
  Index = '/',
  Todo = '/app',
  TodoDone = '/app/todo/done',
  TodoAll = '/app/todo/all',
  Tag = '/app/collection',
  SharedCollectionPrefix = '/app/p',
  Search = '/app/search',
  Reader = '/app/item',
  ContactUs = '/app/contact-us',
  Admin = '/app/admin',
  Settings = '/app/settings',
  Import = '/app/import',
  EditEmail = '/app/settings/edit-email',
  ChangePassword = '/app/settings/change-password',
  DeleteAccount = '/app/settings/delete-account',
  GuideHowToSaveAWebPage = '/guides/save-web-pages-from-anywhere',
  GuideCheckOffTodoItems = '/guides/check-off-to-do-items',
  GuideHowToUseTheBookmarklet = '/guides/using-the-bookmarklet',
  ForgotPassword = '/forgot-password',
  Pwa = '/app/pwa',
  Unknown = '???',
}

const pathToPageType = {
  [PageType.Index]: PageType.Index,
  [PageType.Todo]: PageType.Todo,
  [PageType.TodoDone]: PageType.TodoDone,
  [PageType.TodoAll]: PageType.TodoAll,
  [PageType.SharedCollectionPrefix]: PageType.SharedCollectionPrefix,
  [PageType.Tag]: PageType.Tag,
  [PageType.Search]: PageType.Search,
  [PageType.Reader]: PageType.Reader,
  [PageType.ContactUs]: PageType.ContactUs,
  [PageType.GuideHowToSaveAWebPage]: PageType.GuideHowToSaveAWebPage,
  [PageType.Settings]: PageType.Settings,
};

const getPath = () => {
  return global?.location?.pathname ?? '';
};

const getPageType = (path: string = getPath()): PageType => {
  const splitPath = path.split('/');
  const partialPath4 = splitPath.slice(0, 4).join('/');
  const todoPType = pathToPageType[partialPath4];

  if (todoPType) {
    return todoPType;
  }

  const partialPath = splitPath.slice(0, 3).join('/');
  const pType = pathToPageType[partialPath];

  if (!pType) {
    return PageType.Unknown;
  }

  return pType;
};

const getTagId = (typeOfPage: PageType, id: string | string[]) => {
  if (typeOfPage === PageType.Tag) {
    const tagId = Number(id);

    if (Number.isNaN(tagId)) {
      // if no tag id is provided,
      // then we default to all items
      return systemTag.all.id;
    }

    return tagId;
  }

  return undefined;
};

export const pageInfo = {
  current: {
    pageTypeWithRouter(router: NextRouter) {
      return getPageType(router.pathname);
    },
    get pageType() {
      return getPageType();
    },
    tagIdWithRouter(router: NextRouter): number {
      const { id } = router.query;
      const typeOfPage = getPageType(router.pathname);

      return getTagId(typeOfPage, id);
    },
    sharingKeyWithRouter(router: NextRouter): string {
      const { sharingKey } = router.query;

      return sharingKey as string;
    },
    get tagId(): number {
      const path = getPath();
      const typeOfPage = getPageType();
      const [, , , id] = path.split('/');

      return getTagId(typeOfPage, id);
    },
    get itemId(): number {
      const path = getPath();
      const typeOfPage = getPageType();
      const [, , , itemId] = path.split('/');

      if (typeOfPage === PageType.Reader && itemId) {
        return Number(itemId);
      }

      return undefined;
    },
  },
};

global.pageInfo = pageInfo;
