// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { PopoverProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { HintPopover } from '~/components/HintPopover/HintPopover';
import { useOnboardingCreateTagHint } from './useOnboardingCreateTagHint';

interface Props extends Omit<PopoverProps, 'children'>, PropsWithChildren {}

export const OnboardingCreateTagHint: FC<Props> = ({ children, ...props }) => {
  const tip = useOnboardingCreateTagHint();
  if (tip.isOpen) {
    return (
      <HintPopover
        renderInPortal={false}
        hintText={'Click here to create a collection.'}
        dismissText={'Maybe later'}
        isOpen={tip.isOpen}
        onClose={tip.onClose}
        {...props}
      >
        {children}
      </HintPopover>
    );
  }
  return <>{children}</>;
};
