// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef, Text } from '@chakra-ui/react';
import { NpNote } from '~/components/Icons/NpNote';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface Props extends FlexProps {}

export const ItemWideCardNoNote = forwardRef<Props, 'div'>((props, ref) => {
  return (
    <Flex ref={ref} gap={1} {...props}>
      <ReactIcon as={NpNote} w="16px" h="16px" />
      <Text fontSize="12px" fontStyle="italic" color="npSubduedText">
        Add note
      </Text>
    </Flex>
  );
});
