// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';

interface Props extends ButtonProps {}

export const BottomTabButton = forwardRef<Props, 'button'>(
  ({ isActive, ...props }, ref) => {
    const leftIconSelector = '& > span.chakra-button__icon';
    return (
      <Button
        ref={ref}
        variant="npGhostDust"
        flex="1 0"
        flexShrink={0}
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        fontWeight={!isActive ? 'medium' : undefined}
        h="100%"
        borderRadius={0}
        isActive={isActive}
        {...props}
        sx={{
          [leftIconSelector]: {
            marginInlineEnd: 0,
            ...props.sx?.[leftIconSelector],
          },
          ...props.sx,
        }}
      />
    );
  },
);
