// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { EventKeys, normalizeEventKey } from '@chakra-ui/utils';
import * as React from 'react';
import { useCallback, useState } from 'react';

interface ShortkeyOptions {
  focusedIndex: number;
  max: number;
  incrementIndex: () => void;
  decrementIndex: () => void;
}

type ShortkeyEvent = (
  event: React.KeyboardEvent,
  options: ShortkeyOptions,
) => void;
type ShortKeys = EventKeys | '/';
export type ShortKeyMap = Partial<Record<ShortKeys, ShortkeyEvent>>;

interface ShortkeyInputs {
  default: number;
  max: number;
  handle: ShortKeyMap;
}

interface UseShortkeyOutputs {
  focusedIndex: number;
  setFocusedIndex: React.Dispatch<React.SetStateAction<number>>;
  /** keydown event handler for specific elements */
  onKeyDown: (event: React.KeyboardEvent) => void;
  /** keydown event handler for document.body */
  onKeyDownBody: (event: KeyboardEvent) => void;
}

export function useShortkey(props: ShortkeyInputs): UseShortkeyOutputs {
  // preselect first result
  // if results change, preselect first result
  // if down is pressed, preselect down
  // if up is pressed, preselect up
  // if enter is pressed, apply custom behaviour on selected

  const [focusedIndex, setFocusedIndex] = useState(props.default);

  const incrementIndex = useCallback(() => {
    const nextIndex = focusedIndex + 1;
    const next = nextIndex < props.max;
    if (next) setFocusedIndex(nextIndex);
  }, [focusedIndex, setFocusedIndex, props.max]);

  const decrementIndex = useCallback(() => {
    const prevIndex = focusedIndex - 1;
    const prev = prevIndex >= 0 && props.max;
    if (prev) setFocusedIndex(prevIndex);
  }, [focusedIndex, setFocusedIndex, props.max]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const eventKey = normalizeEventKey(event);

      const fn = props.handle[eventKey];

      if (fn) {
        event.preventDefault();
        fn(event, {
          focusedIndex,
          max: props.max,
          incrementIndex,
          decrementIndex,
        });

        return;
      }
    },
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusedIndex, props.max, props.handle],
  );

  const onKeyDownBody = useCallback(
    (ev: KeyboardEvent) => {
      // prevent shortkeys from interfering with modal shortkeys
      // and typing in textarea
      if (document.activeElement !== document.body) return;
      onKeyDown(ev as unknown as React.KeyboardEvent<HTMLBodyElement>);
    },
    [onKeyDown],
  );

  return {
    focusedIndex,
    setFocusedIndex,
    onKeyDown,
    onKeyDownBody,
  };
}
