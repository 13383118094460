// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { PageType } from '~/common/utilities/pageInfo';
import { IconMenuButton } from '~/components/IconMenuButton';
import { NpAdd } from '~/components/Icons/NpAdd';
import { useModalCollectionCreateContext } from '~/containers/common/Collection/ModalCollectionCreate/useModalCollectionCreateContext';
import { ModalSectionCreateContextTrigger } from '~/containers/common/Section/ModalSectionCreate/ModalSectionCreateContextTrigger';

interface Props {}

export const LeftSidebarMyCollectionsGroupMenu = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    const modalCollectionCreateModal = useModalCollectionCreateContext();

    return (
      <Menu>
        <IconMenuButton
          as={MenuButton}
          variant="npGhost"
          size="sm"
          icon={<NpAdd size="xs" />}
          aria-label="My collections menu"
        />
        <MenuList>
          <MenuItem onClick={modalCollectionCreateModal.onOpen}>
            Create a collection
          </MenuItem>
          <ModalSectionCreateContextTrigger as={MenuItem}>
            Create a section
          </ModalSectionCreateContextTrigger>
          <MenuItem as={Link} href={PageType.Import}>
            Import bookmarks
          </MenuItem>
        </MenuList>
      </Menu>
    );
  },
);
