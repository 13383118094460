// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

const ternaryIf = (
  condition: boolean,
  thenValue: any,
  elseFallbackValue: any,
) => {
  if (condition) {
    return thenValue;
  }
  return elseFallbackValue;
};

export default ternaryIf;
