// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Progress,
  Spacer,
  Text,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import zipObject from 'lodash/zipObject';
import Image from 'next/image';
import { FC, useEffect, useMemo, useRef } from 'react';
import { MdOutlineSearch } from 'react-icons/md';
import { mainMenuMobileHeight } from '~/common/uiTokens';
import { isUrlHttpOrHttps } from '~/common/utilities/url-utils/isUrlHttpOrHttps';
import { FancyFlex } from '~/components/FancyComponents';
import { ReactIcon } from '~/components/Icons';
import { InfiniteScrollView } from '~/components/InfiniteScrollView';
import { useShortkey } from '~/components/useShortkey';
import { useSearchContext } from '~/containers/common/Search/SearchContext/useSearchContext';
import Illustration from '~/images/illustration-no-results.png';
import { datadog } from '~/lib/datadog/datadog-logger';
import { FCBAddUrl } from './FocusableCard/FCBAddUrl';
import { FCBCollection } from './FocusableCard/FCBCollection';
import { FCBItem } from './FocusableCard/FCBItem/FCBItem';
import { FCBSearchHint } from './FocusableCard/FCBSearchHint/FCBSearchHint';
import { FocusableCard } from './FocusableCard/FocusableCard';
import { SearchOperatorLink } from './SearchOperatorLink';
import { useSearchCollections } from './useSearchCollections';
import { useSearchHints } from './useSearchHints';
import { useSearchItems } from './useSearchItems';
import { useSearchUrl } from './useSearchUrl';

export const SearchPopup: FC<Omit<ModalProps, 'children'>> = (props) => {
  const { setSearchTerm, searchTerm } = useSearchContext();
  const inputRef = useRef();

  const searchHints = useSearchHints({ searchTerm, setSearchTerm });
  const tagResults = useSearchCollections({
    searchTerm,
    dismiss: props.onClose,
  });
  const [urlRequest, urlResults] = useSearchUrl({ searchTerm });
  const [itemsRequest, itemResults] = useSearchItems({ searchTerm });

  const focusableResults = useMemo(
    () => [...searchHints, ...tagResults, ...urlResults, ...itemResults],
    [searchHints, tagResults, urlResults, itemResults],
  );

  const focusableIndexMap = useMemo(() => {
    const keys = focusableResults.map((it) => it.key);
    const index = focusableResults.map((_, idx) => idx);
    return zipObject(keys, index);
  }, [focusableResults]);

  const focusInput = () => {
    (inputRef as any)?.current?.focus();
  };

  const { focusedIndex, onKeyDown, setFocusedIndex } = useShortkey({
    default: -1,
    max: focusableResults.length,
    // TODO: update view port when focused element is outside of viewport
    handle: {
      ArrowUp(_, { decrementIndex, focusedIndex: current }) {
        decrementIndex();

        if (current === 0) {
          focusInput();
        }
      },
      ArrowDown(_, { incrementIndex, focusedIndex: current, max }) {
        incrementIndex();

        if (current === max - 1) {
          focusInput();
        }
      },
      Enter(_, { focusedIndex: current }) {
        const el = focusableResults[current];

        if (!el) {
          const log = datadog.generateLog({
            type: '[Monitoring]',
            message: 'Search popup - focusable element does not exist.',
            context: {
              debug: {
                index: current,
                focusableResultsLength: focusableResults.length,
              },
            },
          });
          datadog.logger.then((logger) => logger.warn(...log));

          return;
        }

        el.onClick();
      },
      Escape: props.onClose,
    },
  });

  useEffect(() => {
    // always select nothing on new search term
    setFocusedIndex(-1);
    // TODO: Fix warning appropriately then remove the line below. We don't want to trigger effects when setFocusedIndex changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const noSearchTerm = isEmpty(searchTerm?.trim());
  const expandResults = !noSearchTerm;

  const noUrlFound =
    !isUrlHttpOrHttps(searchTerm) ||
    (urlRequest.variables?.input?.itemUrl === searchTerm &&
      isEmpty(urlRequest.data));

  const noItemsFound =
    itemsRequest.variables?.input?.query === searchTerm &&
    isEmpty(itemsRequest.items) &&
    !itemsRequest.loading;

  const noResults = !noSearchTerm && noUrlFound && noItemsFound;
  // TODO: prevent complete rerender on focus change?

  const enter = !isEmpty(focusableResults);
  const up = !isEmpty(focusableResults) && focusedIndex > 0;
  const down =
    focusableResults.length > 0 && focusedIndex !== focusableResults.length - 1;

  return (
    <Modal
      variant="npDust"
      size={['full', 'full', '2xl']}
      scrollBehavior="inside"
      {...props}
    >
      <ModalOverlay />
      <ModalContent onKeyDown={onKeyDown}>
        <ModalHeader>
          <InputGroup size="lg" my={-2} mx={-4} w="auto">
            <InputLeftElement pointerEvents="none">
              <ReactIcon as={MdOutlineSearch} />
            </InputLeftElement>
            <Input
              ref={inputRef}
              variant="npMinimal"
              type="text"
              name="search"
              placeholder="Search for items and collections..."
              onFocus={(e) => {
                e.target.select();
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoComplete="off"
              autoFocus
            />
          </InputGroup>
        </ModalHeader>
        <ModalBody id="search-modal" bg="transparent">
          <InfiniteScrollView
            scrollableId="search-modal"
            loading={itemsRequest.loading}
            count={itemsRequest.items?.length ?? 0}
            hasMore={itemsRequest.hasMore}
            onNextPage={itemsRequest.nextPage}
          >
            <Flex gap={3} direction="column">
              {searchHints.map((it) => (
                <FocusableCard
                  key={it.key}
                  focused={it.key == focusableResults[focusedIndex]?.key}
                  onClick={it.onClick}
                  onMouseOver={() => setFocusedIndex(focusableIndexMap[it.key])}
                >
                  <FCBSearchHint
                    title={it.title}
                    description={it.description}
                  />
                </FocusableCard>
              ))}
              {!isEmpty(searchHints) && (
                <SearchOperatorLink display={['flex', 'flex', 'none']} />
              )}
              <Heading
                display={
                  expandResults && !isEmpty(tagResults) ? undefined : 'none'
                }
                size="xs"
              >
                Collections
              </Heading>
              {expandResults &&
                tagResults.map((it, idx) => {
                  return (
                    <FocusableCard
                      key={it.key}
                      focused={it.key == focusableResults[focusedIndex]?.key}
                      onClick={it.onClick}
                      onMouseOver={() =>
                        setFocusedIndex(focusableIndexMap[it.key])
                      }
                    >
                      <FCBCollection title={it.title} iconType={it.iconType} />
                    </FocusableCard>
                  );
                })}
              <Heading display={expandResults ? undefined : 'none'} size="xs">
                Items
              </Heading>
              {urlResults.map((it, idx) => {
                return (
                  <FocusableCard
                    key={it.key}
                    focused={it.key == focusableResults[focusedIndex]?.key}
                    onClick={it.onClick}
                    onMouseOver={() =>
                      setFocusedIndex(focusableIndexMap[it.key])
                    }
                  >
                    {it.isInLibrary && (
                      <FCBItem
                        title={it.title}
                        imageSrc={it.imageSrc}
                        url={it.url}
                        note={it.note}
                        collections={it.collections}
                      />
                    )}
                    {it.isInLibrary === false && <FCBAddUrl url={it.url} />}
                  </FocusableCard>
                );
              })}
              {itemResults.map((it, idx) => {
                return (
                  <FocusableCard
                    key={it.key}
                    focused={it.key == focusableResults[focusedIndex]?.key}
                    onClick={it.onClick}
                    onMouseOver={() =>
                      setFocusedIndex(focusableIndexMap[it.key])
                    }
                  >
                    <FCBItem
                      title={it.title}
                      imageSrc={it.imageSrc}
                      url={it.url}
                      note={it.note}
                      collections={it.collections}
                    />
                  </FocusableCard>
                );
              })}
              <Flex
                display={noResults ? undefined : 'none'}
                alignItems="center"
                flexDir="column"
                p={4}
              >
                <Image src={Illustration} height={96} width={130} alt="" />
                <Text mt={2}>No results were found for</Text>
                <Text noOfLines={1} textAlign="center">
                  &quot;{searchTerm}&quot;
                </Text>
              </Flex>
            </Flex>
            <Progress
              visibility={itemsRequest.loading ? undefined : 'hidden'}
              mt={3}
              size="xs"
              colorScheme="whiteAlpha"
              isIndeterminate
            />
          </InfiniteScrollView>
        </ModalBody>
        <ModalFooter display={['none', 'none', 'flex']}>
          <Flex
            display={['none', 'none', 'flex']}
            gap={3}
            alignItems="center"
            w="full"
          >
            <Kbd variant="npBranded">esc</Kbd>
            <SearchOperatorLink />
            <Spacer />
            <Kbd variant="npBranded" display={up ? undefined : 'none'}>
              ↑
            </Kbd>
            <Kbd variant="npBranded" display={down ? undefined : 'none'}>
              ↓
            </Kbd>
            <Kbd variant="npBranded" display={enter ? undefined : 'none'}>
              enter
            </Kbd>
          </Flex>
        </ModalFooter>
        <FancyFlex
          /** so content body doesn't get blocked on mobile */
          exactHeight={mainMenuMobileHeight}
          mobileOnly
        />
      </ModalContent>
    </Modal>
  );
};
