// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useApolloClient } from '@apollo/client';
import {
  Collapse,
  Flex,
  FlexProps,
  forwardRef,
  Spacer,
  TooltipProps,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import NextLink from 'next/link';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import {
  UserPreferenceDocument,
  useUpdatePreferenceMutation,
  useUserPreferenceQuery,
} from '~/common/gql/user.generated';
import { PageType } from '~/common/utilities/pageInfo';
import { systemTag } from '~/common/utilities/systemTag';
import { NpAllItems } from '~/components/Icons/NpAllItems';
import { NpTodo } from '~/components/Icons/NpTodo';
import { NpTrash } from '~/components/Icons/NpTrash';
import { PreviewText } from '~/components/PreviewText/PreviewText';
import { LeftSidebarButton } from '~/containers/common/App/LeftSidebar/LeftSidebarButton';
import { LeftSidebarCollectionButton } from '~/containers/common/App/LeftSidebar/LeftSidebarCollectionButton';
import { LeftSidebarGroup } from '~/containers/common/App/LeftSidebar/LeftSidebarGroup';
import { LeftSidebarGroupHeader } from '~/containers/common/App/LeftSidebar/LeftSidebarGroupHeader';
import { LeftSidebarGroupHeaderButton } from '~/containers/common/App/LeftSidebar/LeftSidebarGroupHeaderButton';
import { LeftSidebarMyCollectionsGroupMenu } from '~/containers/common/App/LeftSidebar/LeftSidebarMyCollectionsGroup/LeftSidebarMyCollectionsGroupMenu';
import { LeftSidebarSection } from '~/containers/common/App/LeftSidebar/LeftSidebarSection/LeftSidebarSection';
import { LeftSidebarSectionContainer } from '~/containers/common/App/LeftSidebar/LeftSidebarSection/LeftSidebarSectionContainer';
import { useAppMenu } from '~/containers/common/App/useAppMenu';
import { OnboardingCreateTagHint } from '~/containers/common/OnboardingHint/OnboardingCreateTagHint/OnboardingCreateTagHint';
import { useCollectionsQueryHelper } from '~/hooks/useCollectionsQueryHelper';

interface Props extends FlexProps {
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebarMyCollectionsGroup = forwardRef<Props, 'div'>(
  ({ tooltipPlacement, ...props }, ref) => {
    const apolloClient = useApolloClient();
    const sectionQuery = useListSectionsQuery();
    const userPreferenceQuery = useUserPreferenceQuery();
    const [userPreferenceMutation] = useUpdatePreferenceMutation();
    const { userCollections } = useCollectionsQueryHelper();
    const { isNavItemActive } = useAppMenu();
    const sections = sectionQuery.data?.listSections ?? [];
    const sectionlessCollections = userCollections.filter((it) =>
      isNil(it.sectionId),
    );
    const isOpen = !(
      userPreferenceQuery.data?.userPreference?.collapseMyCollections ?? false
    );
    const onToggleCollapse = () => {
      const userPreference = userPreferenceQuery.data?.userPreference;
      userPreferenceMutation({
        variables: {
          input: {
            collapseMyCollections: isOpen,
          },
        },
      });
      if (userPreference) {
        // update the cache immediately
        // don't use optimistic update because the request is not guaranteed to
        // be successful and we want user changes to persist even if the
        // request fails, for example: if the user or server is offline
        const documentNode = UserPreferenceDocument;

        apolloClient.cache.writeQuery({
          query: documentNode,
          overwrite: true,
          data: {
            userPreference: {
              ...userPreference,
              collapseMyCollections: isOpen,
            },
          },
        });
      }
    };

    return (
      <LeftSidebarGroup>
        <LeftSidebarGroupHeader>
          <Flex as="h2">
            <LeftSidebarGroupHeaderButton
              isOpen={isOpen}
              onClick={onToggleCollapse}
            >
              My collections
            </LeftSidebarGroupHeaderButton>
          </Flex>
          <Spacer />
          <OnboardingCreateTagHint placement="bottom-end">
            <LeftSidebarMyCollectionsGroupMenu />
          </OnboardingCreateTagHint>
        </LeftSidebarGroupHeader>
        <Collapse in={isOpen} animateOpacity>
          <Flex direction="column" gap={4}>
            <LeftSidebarSectionContainer>
              <LeftSidebarButton
                as={NextLink}
                href={PageType.Todo}
                leftIcon={<NpTodo size="xs" />}
                isActive={isNavItemActive('todo')}
              >
                <PreviewText>To do</PreviewText>
              </LeftSidebarButton>
              <LeftSidebarButton
                as={NextLink}
                href={`${PageType.Tag}/${systemTag.all.id}`}
                leftIcon={<NpAllItems size="xs" />}
                isActive={isNavItemActive('all')}
              >
                <PreviewText>All Items</PreviewText>
              </LeftSidebarButton>
              {sectionlessCollections.map((it) => (
                <LeftSidebarCollectionButton
                  as={NextLink}
                  key={it.id}
                  href={`${PageType.Tag}/${it.id}`}
                  collection={it}
                  tooltipPlacement={tooltipPlacement}
                  isActive={isNavItemActive(it.id)}
                />
              ))}
            </LeftSidebarSectionContainer>
            {sections.map((section) => (
              <LeftSidebarSection
                key={section.id}
                section={section}
                collections={userCollections.filter(
                  (it) => it.sectionId === section.id,
                )}
                tooltipPlacement={tooltipPlacement}
              />
            ))}
            <LeftSidebarButton
              as={NextLink}
              href={`${PageType.Tag}/${systemTag.trash.id}`}
              leftIcon={<NpTrash size="xs" />}
              isActive={isNavItemActive('trash')}
            >
              <PreviewText>Trash</PreviewText>
            </LeftSidebarButton>
          </Flex>
        </Collapse>
      </LeftSidebarGroup>
    );
  },
);
