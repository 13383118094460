// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

export const getBaseUrl = (rawUrl: string) => {
  try {
    const url = new URL(rawUrl);
    return url.host;
  } catch {
    return;
  }
};
