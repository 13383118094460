// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { copy } from '~/common/copy/copy';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { useCollectionCreateQueryHelper } from '~/hooks/useCollectionCreateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useModalCollectionCreateContext } from './useModalCollectionCreateContext';

export const ModalCollectionCreate: FC = () => {
  const modal = useModalCollectionCreateContext();
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      title: '',
    },
  });
  const [createCollection] = useCollectionCreateQueryHelper();
  const collectionsQuery = useCollectionsQuery();
  const { renderToastWith } = useToastHelper();

  const {
    errors: { title: titleErrors },
  } = formState;

  const onClose = () => {
    reset();
    modal.onClose();
    modal.setSectionId(null);
  };

  const onSubmit = ({ title }) => {
    createCollection(
      {
        input: { title, sectionId: modal.sectionId },
      },
      {
        onSuccess: renderToastWith({
          message: copy.createCollectionSuccess,
          then: () => {
            onClose();
            collectionsQuery.refetch();
          },
        }),
        onError: (e) => {
          const errors = e?.graphQLErrors
            ?.map((it) => it.exception?.detailMessage ?? it.message)
            .join('\n');

          renderToastWith({
            message: errors ?? copy.createCollectionError,
          })();
        },
      },
    );
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader textAlign="center">New collection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!titleErrors}>
            <Input
              variant="npFlushed"
              aria-label="title"
              name="title"
              type="text"
              placeholder="Title"
              textAlign="center"
              autoComplete="off"
              autoFocus
              isRequired
              {...register('title', { required: 'Title is required' })}
            />
            <FormErrorMessage>{titleErrors?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="npPrimary" type="submit">
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
