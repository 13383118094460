// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { ModalSectionCreateContext } from './ModalSectionCreateContext';

export const ModalSectionCreateContextProvider: FC<PropsWithChildren> = ({
  ...props
}) => {
  const disclosure = useDisclosure();

  return <ModalSectionCreateContext.Provider value={disclosure} {...props} />;
};
