// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text, TextProps } from '@chakra-ui/react';

interface Props extends TextProps {}

export const WideCardNoteText = forwardRef<Props, 'p'>((props, ref) => {
  return (
    <Text
      ref={ref}
      fontSize="xs"
      wordBreak="break-all"
      noOfLines={1}
      data-dd-privacy="mask"
      {...props}
    />
  );
});
