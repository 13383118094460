// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Card,
  CardBody,
  ContainerProps,
  forwardRef,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PWAInstall } from '~/common/pwa';
import { PageType } from '~/common/utilities/pageInfo';

export const InstallPWAAd = forwardRef<ContainerProps, 'div'>((props, ref) => {
  return (
    <Card ref={ref} variant="npAccent" {...props}>
      <CardBody>
        <Stack>
          <Text>
            Psst, save items faster by installing our Progressive Web App.
          </Text>
          <Stack direction="row">
            <Button variant="npPrimary" size="sm" onClick={PWAInstall}>
              Install the app
            </Button>
            <Link href={PageType.Pwa}>
              <Button variant="npOutline" size="sm">
                Learn more
              </Button>
            </Link>
          </Stack>
        </Stack>
      </CardBody>
    </Card>
  );
});
