// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { ButtonProps, forwardRef, TooltipProps } from '@chakra-ui/react';
import { OverflowTooltip } from '~/components/OverflowTooltip';
import { PreviewText } from '~/components/PreviewText/PreviewText';
import { LeftSidebarButton } from './LeftSidebarButton';

interface Props extends ButtonProps {
  oid: string;
  label: string;
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebarButtonOverflow = forwardRef<Props, 'button'>(
  ({ oid, label, tooltipPlacement, children, ...props }, ref) => {
    const overflowClassName = `left-sidebar-button-${oid}`;
    return (
      <LeftSidebarButton {...props}>
        <OverflowTooltip
          placement={tooltipPlacement}
          overflowClassName={overflowClassName}
          label={label}
        >
          <PreviewText
            className={overflowClassName}
            data-dd-privacy="mask"
            data-list-title
          >
            {children}
          </PreviewText>
        </OverflowTooltip>
      </LeftSidebarButton>
    );
  },
);
