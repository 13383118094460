// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const WideCardContentCell = forwardRef<Props, 'div'>((props, ref) => {
  return (
    <Flex
      ref={ref}
      flex="1 1 auto"
      direction="column"
      cursor="pointer"
      {...props}
    />
  );
});
