// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { toHSLA } from '@niphtio/np-theme';
import { parse } from 'tldts';

export const getUrlColor = (url: string): string => {
  const parsedUrl = parse(url);
  return toHSLA(parsedUrl.domain);
};
