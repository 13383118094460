// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { SearchContext } from './SearchContext';

export const useSearchContext = () => {
  const props = useContext(SearchContext);

  return props;
};
