// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { UseDisclosureReturn } from '@chakra-ui/react';
import { createContext } from 'react';
import { Section } from '~/common/gql';

/**
 * Used to manage UI components in /app/** pages.
 */
export interface ModalCollectionCreateContextProps extends UseDisclosureReturn {
  sectionId: Section['id'] | undefined | null;
  setSectionId: (sectionId: Section['id'] | undefined | null) => void;
}

export const ModalCollectionCreateContext =
  createContext<ModalCollectionCreateContextProps>({
    sectionId: undefined,
    setSectionId: function (): void {
      throw new Error('Function not implemented.');
    },
    isOpen: false,
    onOpen: function (): void {
      throw new Error('Function not implemented.');
    },
    onClose: function (): void {
      throw new Error('Function not implemented.');
    },
    onToggle: function (): void {
      throw new Error('Function not implemented.');
    },
    isControlled: false,
    getButtonProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
    getDisclosureProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
  });
