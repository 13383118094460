// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useEffect } from 'react';
import { Order } from '~/common/gql';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { useItemsQuery } from '~/common/gql/item.generated';
import { useUserPreferenceQuery } from '~/common/gql/user.generated';
import { useOnboardingHintBase } from '~/containers/common/OnboardingHint/useOnboardingHintBase';

/**
 * This onboarding hint should only appear when the user has more than
 * 5 items and no user collections. It should never appear again after
 * being dismissed by the user.
 *
 * @returns
 */
export const useOnboardingCreateTagHint = () => {
  const { isOpen, onOpen, onClose } =
    useOnboardingHintBase('hideCreateTagHint');
  const { data, loading, error, called } = useUserPreferenceQuery();

  const collectionsQuery = useCollectionsQuery();
  const itemsQuery = useItemsQuery({
    variables: {
      input: {},
      pagination: {
        limit: 5,
        order: Order.Desc,
      },
    },
  });

  useEffect(() => {
    if (!loading && called && !error) {
      // hide onboarding hints until we receive the user's preferences
      const isNotHiddenRemotely =
        data?.userPreference?.hideCreateTagHint === false;
      const isTipHelpful =
        collectionsQuery.data?.collectionQuery.length === 0 &&
        itemsQuery.data?.itemsQueryPaginated.items.length >= 5;

      if (isNotHiddenRemotely && isTipHelpful) {
        // only show hint if it's helpful
        // and is not set to hidden remotely
        onOpen();
        return;
      }
    }
  }, [
    called,
    collectionsQuery.data?.collectionQuery.length,
    data?.userPreference?.hideCreateTagHint,
    error,
    itemsQuery.data?.itemsQueryPaginated.items.length,
    loading,
    onOpen,
  ]);

  return {
    isOpen,
    onClose,
  };
};
