// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { FCBSearchHintProps } from './FocusableCard/FCBSearchHint/FCBSearchHint';
import { FocusableProps } from './FocusableCard/FocusableProps';

interface Props {
  namespace?: string;
  searchTerm: string;
  setSearchTerm: (nextSearchTerm: string) => void;
}

export interface SearchHintType extends FCBSearchHintProps {
  id: string;
  token: string;
}

const hints: SearchHintType[] = [
  {
    id: 'exact-match',
    token: '""',
    title: '“”',
    description: 'Find exact matches to the term — e.g. “dinosaur eggs”',
  },
  {
    id: 'todo-incomplete',
    token: 'is:incomplete',
    title: 'is:incomplete',
    description: 'Find incomplete to do items',
  },
  {
    id: 'filter',
    token: '-',
    title: '-',
    description: 'Show results that don’t have the term — e.g. -dinosaur',
  },
];

interface FocusableSearchHintProps extends FocusableProps, FCBSearchHintProps {}

export const useSearchHints = ({
  namespace = 'hint',
  searchTerm,
  setSearchTerm,
}: Props): FocusableSearchHintProps[] => {
  const noSearchTerm = isEmpty(searchTerm?.trim());
  return noSearchTerm
    ? hints.map(
        ({ id, ...it }) =>
          ({
            key: `${namespace}:${id}`,
            title: it.title,
            description: it.description,
            onClick: () => {
              setSearchTerm(searchTerm + it.token);
            },
          }) satisfies FocusableSearchHintProps,
      )
    : [];
};
