// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, forwardRef } from '@chakra-ui/react';
import { getBaseUrl } from '~/common/utilities/url-utils/getBaseUrl';
import { getUrlAbbreviation } from '~/common/utilities/url-utils/getUrlAbbreviation';
import { getUrlColor } from '~/common/utilities/url-utils/getUrlColor';
import { WideCardContentCell } from '~/containers/common/WideCard/WideCardContentCell';
import { WideCardMetadataCell } from '~/containers/common/WideCard/WideCardMetadataCell';
import {
  WideCardFlexNote,
  WideCardNoteText,
} from '~/containers/common/WideCard/WideCardNote';
import { WideCardThumbnailCell } from '~/containers/common/WideCard/WideCardThumbnailCell';
import { WideTitle } from '~/containers/common/WideCard/WideTitle';
import { WideUrl } from '~/containers/common/WideCard/WideUrl';
import { FocusableCardBody } from '../FocusableCardBody';
import { FCBItemCollectionBlock } from './FCBItemCollectionBlock';

export interface FCBItemProps {
  title: string;
  url?: string;
  note?: string;
  collections?: string[];
  imageSrc?: string;
}

const maxCollections = 2;
export const FCBItem = forwardRef<FCBItemProps, 'div'>(
  ({ title, imageSrc, url, note, collections }, ref) => {
    const baseUrl = getBaseUrl(url);
    const fallbackHeading = getUrlAbbreviation(url);
    const fallbackBg = getUrlColor(url);
    return (
      <FocusableCardBody>
        <WideCardThumbnailCell
          title={title}
          imageSrc={imageSrc}
          fallbackHeading={fallbackHeading}
          fallbackBg={fallbackBg}
        />
        <Flex
          gap={2}
          flex="1 1 auto"
          direction={['column', 'column', 'column', 'row']}
        >
          <WideCardContentCell>
            <WideTitle noOfLines={4}>{title}</WideTitle>
            <WideUrl>{baseUrl}</WideUrl>
            {note && (
              <WideCardNoteText
                display={['-webkit-box', '-webkit-box', '-webkit-box', 'none']}
              >
                {note}
              </WideCardNoteText>
            )}
          </WideCardContentCell>
          <WideCardMetadataCell justifyContent="center">
            <Flex gap={1} direction="row">
              <FCBItemCollectionBlock collections={collections} />
            </Flex>
            <WideCardFlexNote
              display={['none', 'none', 'none', 'flex']}
              _hover={{ bg: 'none' }}
            >
              {note && (
                <WideCardNoteText fontWeight="medium" noOfLines={2}>
                  {note}
                </WideCardNoteText>
              )}
            </WideCardFlexNote>
          </WideCardMetadataCell>
        </Flex>
      </FocusableCardBody>
    );
  },
);
