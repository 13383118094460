// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';
import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpPartyEmoji = forwardRef<ReactIconProps, 'svg'>((props, ref) => {
  return (
    <ReactIcon ref={ref} viewBox="0 0 41 43" aria-label="Niphtio" {...props}>
      <path
        d="M19.2603 42.5857C10.7609 42.5857 1.58325 37.2552 1.58325 25.5561C1.58325 13.8571 10.7609 8.52661 19.2603 8.52661C23.981 8.52661 28.3386 10.0785 31.5691 12.9116C35.0702 16.0222 36.9304 20.4072 36.9304 25.5561C36.9304 30.7051 35.0702 35.0627 31.5691 38.1698C28.3386 41.003 23.9501 42.5857 19.2603 42.5857Z"
        fill="url(#paint0_radial_537_37499)"
      />
      <path
        d="M33.8503 15.4329C35.5221 18.2077 36.3785 21.4006 36.3203 24.6413C36.3203 29.78 34.4601 34.1479 30.959 37.255C27.7284 40.0882 23.3435 41.6709 18.6501 41.6709C13.1483 41.6709 7.36906 39.4338 3.97412 34.6823C7.25259 40.0642 13.4156 42.5856 19.2599 42.5856C23.9498 42.5856 28.3382 41.0028 31.5687 38.1697C35.0699 35.0591 36.9301 30.705 36.9301 25.556C36.9301 21.6746 35.8715 18.2249 33.8503 15.4329Z"
        fill="#EB8F00"
      />
      <path
        opacity="0.8"
        d="M9.05126 30.0131C12.3623 30.0131 15.0464 27.4823 15.0464 24.3605C15.0464 21.2387 12.3623 18.708 9.05126 18.708C5.74025 18.708 3.05615 21.2387 3.05615 24.3605C3.05615 27.4823 5.74025 30.0131 9.05126 30.0131Z"
        fill="url(#paint1_radial_537_37499)"
      />
      <path
        opacity="0.8"
        d="M29.7432 30.0131C33.0542 30.0131 35.7383 27.4823 35.7383 24.3605C35.7383 21.2387 33.0542 18.708 29.7432 18.708C26.4321 18.708 23.748 21.2387 23.748 24.3605C23.748 27.4823 26.4321 30.0131 29.7432 30.0131Z"
        fill="url(#paint2_radial_537_37499)"
      />
      <path
        d="M5.69419 6.68359C7.01859 6.68359 8.09223 5.9167 8.09223 4.9707C8.09223 4.0247 7.01859 3.25781 5.69419 3.25781C4.36978 3.25781 3.29614 4.0247 3.29614 4.9707C3.29614 5.9167 4.36978 6.68359 5.69419 6.68359Z"
        fill="#FF00A2"
      />
      <path
        d="M15.7821 9.85937C16.344 9.29748 16.203 8.24548 15.4672 7.50967C14.7314 6.77386 13.6794 6.63287 13.1175 7.19476C12.5556 7.75666 12.6966 8.80865 13.4324 9.54446C14.1682 10.2803 15.2202 10.4213 15.7821 9.85937Z"
        fill="#FD3B3B"
      />
      <path
        d="M23.1307 7.12732C24.3875 6.15452 25.0402 4.89288 24.5886 4.30936C24.1369 3.72585 22.7519 4.04142 21.4951 5.01422C20.2383 5.98702 19.5856 7.24867 20.0373 7.83218C20.4889 8.4157 21.8739 8.10012 23.1307 7.12732Z"
        fill="#00EEFF"
      />
      <path
        d="M6.36493 29.7409C6.84009 29.4665 6.72681 28.3808 6.11191 27.3158C5.49701 26.2507 4.61334 25.6098 4.13818 25.8841C3.66302 26.1585 3.77631 27.2442 4.39121 28.3092C5.00611 29.3742 5.88978 30.0152 6.36493 29.7409Z"
        fill="#0048FF"
      />
      <path
        d="M30.5311 39.5708C31.1933 39.5708 31.7301 38.7273 31.7301 37.6867C31.7301 36.6461 31.1933 35.8025 30.5311 35.8025C29.8689 35.8025 29.332 36.6461 29.332 37.6867C29.332 38.7273 29.8689 39.5708 30.5311 39.5708Z"
        fill="#F63BBE"
      />
      <path
        d="M37.3925 19.9513C38.3981 19.2435 39.0368 18.4188 38.819 18.1094C38.6012 17.7999 37.6094 18.123 36.6038 18.8308C35.5982 19.5387 34.9595 20.3634 35.1773 20.6728C35.3951 20.9822 36.3869 20.6592 37.3925 19.9513Z"
        fill="#00CC00"
      />
      <path
        d="M5.17348 41.2962C5.56895 41.0367 5.38469 40.0569 4.76192 39.1077C4.13915 38.1586 3.3137 37.5995 2.91822 37.859C2.52275 38.1185 2.707 39.0983 3.32978 40.0474C3.95255 40.9966 4.778 41.5556 5.17348 41.2962Z"
        fill="#0048FF"
      />
      <path
        d="M2.6716 34.6019C3.80396 34.5215 4.69478 34.0739 4.66129 33.6021C4.6278 33.1302 3.68269 32.8129 2.55033 32.8933C1.41796 32.9737 0.527148 33.4213 0.560636 33.8931C0.594125 34.3649 1.53923 34.6823 2.6716 34.6019Z"
        fill="#7ACDED"
      />
      <path
        d="M5.73501 30.1151C7.09425 30.0247 8.17882 29.6912 8.15747 29.3702C8.13611 29.0493 7.01691 28.8625 5.65767 28.9529C4.29842 29.0434 3.21385 29.3769 3.2352 29.6978C3.25656 30.0187 4.37576 30.2056 5.73501 30.1151Z"
        fill="#FF82B2"
      />
      <path
        d="M38.2048 26.6214C37.9136 25.6142 37.2182 24.7715 36.2864 24.2919C34.7105 23.4354 32.7373 24.0213 31.8842 25.5971C31.8774 25.6074 31.874 25.6177 31.8671 25.6279C31.1545 26.9708 31.6581 28.6392 32.9976 29.362C33.5286 29.6601 34.1521 29.7457 34.7448 29.6018C35.3545 29.4648 35.8684 29.064 36.1493 28.5056C36.4542 27.8615 36.4268 27.1079 36.0808 26.4844C35.7999 25.9363 35.2552 25.5731 34.642 25.5252C34.2891 25.5183 34.0014 25.7992 33.9945 26.1521C33.9911 26.4707 34.224 26.7447 34.5392 26.7927C34.7139 26.803 34.8715 26.9092 34.9503 27.0668C35.0702 27.31 35.0942 27.5943 35.0188 27.8547C34.9023 28.0842 34.6899 28.2487 34.4364 28.3C34.159 28.372 33.8643 28.3343 33.6143 28.1973C33.2717 28.0157 33.0147 27.7074 32.8948 27.3408C32.7921 26.964 32.8297 26.5632 32.9976 26.2103C33.2374 25.7444 33.6588 25.395 34.1624 25.2511C34.666 25.1072 35.2004 25.1552 35.6697 25.3881C36.2829 25.7239 36.7386 26.2925 36.9373 26.964C37.1462 27.6389 37.0709 28.3686 36.7317 28.9852C36.0808 30.2185 34.8133 31.0407 32.9634 31.0407C31.9014 31.0407 25.5808 30.8694 25.5808 30.8694L25.6562 32.4452L32.8948 32.3425C35.0531 32.3425 36.8345 31.486 37.8622 29.5676C38.4035 28.6837 38.5268 27.6046 38.2048 26.6214Z"
        fill="url(#paint3_linear_537_37499)"
      />
      <path
        d="M21.4524 31.6916C21.6614 31.9142 21.8224 32.1746 21.932 32.4589L25.8546 32.435C25.8546 31.9211 25.786 31.4072 25.786 30.8591C24.7583 30.8248 22.6412 30.7906 21.9834 30.7666C21.8875 31.1195 21.6991 31.4415 21.4319 31.6916H21.4524Z"
        fill="#7F0099"
      />
      <path
        d="M33.3739 18.6052L34.5729 18.7765L34.8813 17.5775L35.0526 16.8923L28.1667 15.9331C31.2842 18.2969 33.1684 18.6052 33.3739 18.6052Z"
        fill="#FF4545"
      />
      <path
        d="M38.8892 1.68188L36.1144 3.25774L35.4292 3.63458L38.3068 4.04567C38.5124 3.18923 38.8892 1.68188 38.8892 1.68188Z"
        fill="#FF4545"
      />
      <path
        d="M32.7575 5.14185L29.9141 6.75196L37.348 7.81395L37.8276 5.86126L32.7575 5.14185Z"
        fill="#FF4545"
      />
      <path
        d="M24.6383 9.73236H24.604L36.4229 11.4452L36.8683 9.62959L27.2419 8.25928L24.6383 9.73236Z"
        fill="#FF4545"
      />
      <path
        d="M25.6317 13.6722L35.4979 15.0767L35.9775 13.2268L24.0901 11.5139C24.2271 11.8908 24.6382 12.6444 25.6317 13.6722Z"
        fill="#FF4545"
      />
      <path
        d="M27.2422 8.25929L36.8686 9.6296L37.3482 7.81394L29.9143 6.75195L27.2422 8.25929Z"
        fill="#FF9C9C"
      />
      <path
        d="M38.1019 4.79921C38.1019 4.79921 38.2046 4.3196 38.2731 4.07979L35.3955 3.6687L32.7234 5.17604L37.7935 5.89546L38.1019 4.79921Z"
        fill="#FF9C9C"
      />
      <path
        d="M23.6111 10.2805L23.9879 11.3767C24.0016 11.4384 24.0256 11.4966 24.0564 11.548L35.9439 13.2609L36.3892 11.4452L24.5703 9.7666L23.6111 10.2805Z"
        fill="#FF9C9C"
      />
      <path
        d="M28.0304 15.8303C28.0646 15.8645 28.1331 15.8988 28.1674 15.9331L35.0532 16.9265L35.4986 15.1109L25.6323 13.7063C26.3826 14.4668 27.1842 15.176 28.0304 15.8303Z"
        fill="#FF9C9C"
      />
      <path
        d="M38.8558 3.70305C39.764 3.70305 40.5002 2.96684 40.5002 2.05868C40.5002 1.15052 39.764 0.414307 38.8558 0.414307C37.9476 0.414307 37.2114 1.15052 37.2114 2.05868C37.2114 2.96684 37.9476 3.70305 38.8558 3.70305Z"
        fill="#C93737"
      />
      <path
        d="M22.083 31.2908C22.5866 30.8557 22.9018 30.2425 22.9634 29.5779C23.0594 28.9099 22.8607 28.235 22.4153 27.728C21.6171 26.6797 19.3972 26.9401 19.3972 26.9401C18.8011 26.964 18.1125 27.2484 18.1982 27.9335C18.3112 28.3275 18.6298 28.629 19.0272 28.718C19.9042 28.927 21.1546 28.718 21.1889 29.6087C21.23 30.6091 19.6952 30.198 19.5719 31.2565C19.4486 32.3151 21.1889 32.1746 21.1889 32.9043C21.1889 33.4627 20.6168 33.5587 20.0138 33.6854C19.4965 33.7916 18.5031 33.9526 18.4243 34.5453C18.3455 35.1379 18.6846 35.8676 20.0824 35.6552C22.2817 35.3126 23.0217 34.1171 23.0217 33.3771C23.0354 32.5755 22.6928 31.8115 22.083 31.2908Z"
        fill="#422B0D"
      />
      <path
        d="M14.5535 22.2467L14.5021 22.1816L14.365 22.0069L14.1903 21.8185C14.1218 21.7431 14.0396 21.6609 13.9574 21.5787C13.8717 21.4965 13.7827 21.4177 13.6867 21.3457C13.6011 21.2807 13.512 21.2224 13.4161 21.171C13.351 21.1333 13.2791 21.1059 13.2037 21.0922C13.1797 21.0888 13.1592 21.0888 13.1352 21.0922C13.1352 21.0922 13.1352 21.0922 13.1078 21.0922H13.1694H12.9913C12.9468 21.0922 12.9913 21.0922 12.9913 21.0922H13.0153C13.0016 21.0888 12.9913 21.0888 12.9776 21.0922C12.9022 21.1059 12.8303 21.1333 12.7652 21.171C12.6693 21.2224 12.5802 21.2807 12.4946 21.3457C12.4055 21.4143 12.313 21.4965 12.2239 21.5787C12.0561 21.7431 11.9088 21.9213 11.806 22.0275L11.6415 22.2227L11.5662 22.3084C11.1003 22.8257 10.3192 22.9079 9.75394 22.5037C9.39424 22.2536 9.20925 21.8185 9.27776 21.3834C9.27776 21.3834 9.30174 21.2532 9.36341 21.0408C9.45933 20.712 9.59636 20.3968 9.76765 20.1022C10.0349 19.6157 10.398 19.1875 10.8331 18.8449C11.1311 18.6188 11.46 18.4373 11.8128 18.3105C11.9088 18.2728 12.0115 18.242 12.1109 18.218C12.2239 18.1837 12.337 18.1598 12.4535 18.1426L12.6522 18.1152H12.8166H12.9947H13.2482H13.4127C13.5189 18.1152 13.6285 18.1426 13.7347 18.1598C13.9437 18.1975 14.1458 18.2523 14.3445 18.3242C14.6973 18.451 15.0262 18.6325 15.3243 18.8586C15.7662 19.1944 16.1396 19.6157 16.4137 20.0988C16.513 20.2666 16.6021 20.4448 16.674 20.6263C16.7391 20.7771 16.7871 20.9381 16.8282 21.058C16.8693 21.1779 16.8659 21.2361 16.8796 21.2944V21.3731C16.9721 22.0343 16.5096 22.6475 15.8484 22.74C15.8347 22.7435 15.821 22.7435 15.8107 22.7435C15.3345 22.8154 14.8515 22.627 14.5535 22.2467Z"
        fill="#422B0D"
      />
      <path
        d="M27.2766 22.2467L27.2252 22.1816L27.0881 22.0069L26.934 21.8185C26.8655 21.7431 26.7833 21.6609 26.701 21.5787C26.6154 21.4965 26.5263 21.4177 26.4304 21.3457C26.3448 21.2807 26.2557 21.2224 26.1598 21.171C26.0947 21.1333 26.0227 21.1059 25.9508 21.0922C25.9268 21.0888 25.9063 21.0888 25.8823 21.0922C25.8823 21.0922 25.8617 21.0922 25.8549 21.0922H25.9063H25.7281C25.6802 21.0922 25.7281 21.0922 25.7281 21.0922H25.7521H25.7144C25.6425 21.1059 25.5705 21.1333 25.5054 21.171C25.4095 21.2224 25.3205 21.2807 25.2348 21.3457C25.1389 21.4177 25.0498 21.4965 24.9642 21.5787C24.7929 21.7431 24.649 21.9213 24.5428 22.0275L24.3784 22.2227L24.303 22.3084C23.8371 22.8257 23.056 22.9079 22.4908 22.5037C22.1311 22.2536 21.9461 21.8185 22.0146 21.3834C22.0146 21.3834 22.0386 21.2532 22.1036 21.0408C22.1893 20.7085 22.316 20.3865 22.4805 20.0851C22.7545 19.602 23.128 19.1807 23.5699 18.8449C23.8679 18.6188 24.2002 18.4373 24.5531 18.3105C24.649 18.2728 24.7483 18.242 24.8477 18.218C24.9607 18.1837 25.0738 18.1598 25.1903 18.1426L25.389 18.1152H25.5534H25.7315H25.9851H26.1495C26.2591 18.1152 26.3653 18.1426 26.4715 18.1598C27.0505 18.2591 27.5952 18.4955 28.0645 18.8449C28.5064 19.1807 28.8764 19.602 29.1539 20.0851C29.2532 20.2529 29.3389 20.4311 29.4108 20.6126C29.4725 20.7531 29.5239 20.897 29.565 21.0443C29.589 21.1231 29.6061 21.2019 29.6164 21.2807V21.3594C29.7123 22.0172 29.2532 22.6304 28.5955 22.7229C28.5818 22.7263 28.5681 22.7263 28.551 22.7298C28.0713 22.8051 27.5849 22.6236 27.2766 22.2467Z"
        fill="#422B0D"
      />
      <defs>
        <radialGradient
          id="paint0_radial_537_37499"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.2568 25.5562) scale(17.3545 17.3545)"
        >
          <stop offset="0.5" stopColor="#FDE030" />
          <stop offset="0.92" stopColor="#F7C02B" />
          <stop offset="1" stopColor="#F4A223" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_537_37499"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.05124 25.5661) scale(6.52534 5.85949)"
        >
          <stop stopColor="#ED7770" />
          <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_537_37499"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(40.8153 401.535) scale(6.65628)"
        >
          <stop stopColor="#ED7770" />
          <stop offset="0.9" stopColor="#ED7770" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_537_37499"
          x1="25.6288"
          y1="28.1717"
          x2="38.3692"
          y2="28.1717"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004054" />
          <stop offset="0.99" stopColor="#45A4FF" />
        </linearGradient>
      </defs>
    </ReactIcon>
  );
});
