// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import _throttle from 'lodash/throttle';
import { useEffect } from 'react';
import { UserPreferenceInput } from '~/common/gql';
import {
  useUpdatePreferenceMutation,
  useUserPreferenceQuery,
} from '~/common/gql/user.generated';

type PreferenceType = keyof Omit<UserPreferenceInput, 'collectionLayout'>;

const throttle = _throttle(
  (fn) => {
    fn();
  },
  3000, // 3 seconds
  { leading: true, trailing: false },
);

export const useOnboardingHintBase = (preferenceType: PreferenceType) => {
  const { isOpen, onOpen, onClose: _onClose } = useDisclosure();
  const { data, loading, error, called, refetch } = useUserPreferenceQuery();
  const [updatePreferenceMutation] = useUpdatePreferenceMutation();

  const onClose = () => {
    updatePreferenceMutation({
      variables: {
        input: {
          [preferenceType]: true,
        },
      },
    }).then(
      () => refetch(),
      noop, // fail silently
    );
    _onClose(); // optimistic update
  };

  useEffect(() => {
    if (!loading && called && !error) {
      const isNoPreferencesSet = isNil(data?.userPreference);
      if (isNoPreferencesSet) {
        // if the user has no preferences, create preference object
        throttle(() => updatePreferenceMutation({ variables: { input: {} } }));
      }
    }
  }, [called, data?.userPreference, error, loading, updatePreferenceMutation]);

  return {
    isOpen,
    onOpen,
    onClose,
  };
};
