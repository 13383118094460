// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { localSessionStore } from '~/common/utilities/session-store/localSessionStore';
import { SS_SEARCH_TERM_KEY } from '~/common/utilities/session-store/sessionStorageKeys';
import { SearchContext } from './SearchContext';

/**
 * Stores the search term in the session storage so it will persist
 * across page changes within a tab.

 */
export const SearchContextProvider: FC<PropsWithChildren> = ({ ...props }) => {
  const [searchTerm, setSearch] = useState('');

  useEffect(() => {
    setSearch(localSessionStore.getItem(SS_SEARCH_TERM_KEY) || '');
  }, []);

  const setSearchTerm = (term) => {
    localSessionStore.setItem(SS_SEARCH_TERM_KEY, term);
    setSearch(term);
  };

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm: setSearchTerm,
      }}
      {...props}
    />
  );
};
