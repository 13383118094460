// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { FlexProps, forwardRef } from '@chakra-ui/react';
import { mainMenuDesktopWidth } from '~/common/uiTokens';
import { FancyFlex } from '~/components/FancyComponents';

interface Props extends FlexProps {}

/**
 * Space on the left side that's available on desktop only.
 */
export const LeftSideLayout = forwardRef<Props, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <FancyFlex
        ref={ref}
        flex="0 0 auto"
        direction="column"
        position="fixed"
        left={0}
        bottom={0}
        top={['initial', 'initial', 0]}
        w={mainMenuDesktopWidth}
        exactHeight={'100vh'}
        overflow="hidden"
        bg="blackAlpha.100"
        _dark={{ bg: 'whiteAlpha.200' }}
        {...props}
        desktopOnly
      >
        {children}
      </FancyFlex>
    );
  },
);
