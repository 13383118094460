// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  forwardRef,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  isBrowser,
  isBrowserSupported,
  isBrowserSupportModalDismissed,
  setBrowserSupportDimissed,
} from '~/common/utilities/browser-utils';

interface Props extends AlertProps {}

export const NoticeUnsupportBrowser = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    const [hideBrowserWarning, setHideBrowserWarning] = useState(false);
    const browserSupported = isBrowser && isBrowserSupported(window);
    const [displayBrowserWarning, setDisplayBrowserWarning] = useState(false);

    useEffect(() => {
      const showWarning =
        isBrowser &&
        !browserSupported &&
        !hideBrowserWarning &&
        !isBrowserSupportModalDismissed();
      setDisplayBrowserWarning(showWarning);
    }, [browserSupported, hideBrowserWarning]);

    if (!displayBrowserWarning) {
      return <></>;
    }

    return (
      <Alert ref={ref} variant="npNotice" size="lg" {...props}>
        <Box>
          <Flex justify="flex-end"></Flex>
          <AlertTitle>Update your browser for the best experience.</AlertTitle>
          <AlertDescription>
            We highly recommend installing <b>Google Chrome</b>, <b>Firefox</b>
            ,&nbsp;
            <b>Microsoft Edge</b>, or <b>Safari</b> to make sure all the
            features work properly.
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="absolute"
          right={4}
          top={4}
          onClick={() => {
            setBrowserSupportDimissed();
            setHideBrowserWarning(true);
          }}
        />
      </Alert>
    );
  },
);
