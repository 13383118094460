// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpTodo = (props: ReactIconProps) => (
  <ReactIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3452 5.1367C11.5736 5.27451 11.7836 5.40121 12 5.5C12.2164 5.40121 12.4264 5.27451 12.6548 5.1367C12.8605 5.01262 13.081 4.87954 13.3347 4.74971C14.0956 4.36023 15.1541 4 17 4C19.3585 4 21.5542 4.9444 22.5047 5.4166C22.8215 5.574 23 5.67893 23 5.67893V19.0062C23 19.0062 19.8689 18 17 18C16.2142 18 15.5584 18.0887 15 18.2241C14.0751 18.4482 13.4172 18.8003 12.8773 19.0892C12.553 19.2627 12.2713 19.4135 12 19.5C11.7287 19.4135 11.447 19.2627 11.1227 19.0892C10.5828 18.8003 9.92486 18.4482 9 18.2241C8.44156 18.0887 7.78582 18 7 18C4.13106 18 1 19.0062 1 19.0062V5.67893C1 5.67893 3.85534 4 7 4C9.46122 4 10.5226 4.6404 11.3452 5.1367ZM3 16.698C3.5 16.5 5.38136 16 7 16C7.71542 16 8.38303 16.1511 9.00001 16.3433C9.3372 16.4483 9.65927 16.5656 9.96575 16.6773C9.96574 16.6773 9.96577 16.6773 9.96575 16.6773C10.3331 16.8111 10.6782 16.9368 11 17.0234V7C11 7 8.89192 6 7 6C5.10808 6 3.81802 6.49696 3 6.83285V16.698ZM15 16.3433C15.617 16.1511 16.2846 16 17 16C18.6186 16 20.5 16.5 21 16.698V6.83285C20.8977 6.79086 20.7881 6.74636 20.6709 6.70062C19.8501 6.38049 18.6554 6 17 6C15.5811 6 14.0405 6.5625 13.3589 6.84375C13.1318 6.9375 13 7 13 7V17.0234C13.0402 17.0126 13.0808 17.0011 13.1218 16.9891C13.4084 16.9053 13.7127 16.7944 14.0342 16.6773C14.0342 16.6773 14.0342 16.6773 14.0342 16.6773C14.3407 16.5657 14.6628 16.4483 15 16.3433Z"
      fill="currentColor"
    />
  </ReactIcon>
);
