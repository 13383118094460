// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { copy } from '~/common/copy/copy';
import { Section } from '~/common/gql';
import { useCollectionsQuery } from '~/common/gql/collection.generated';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import { useSectionDeleteMutationHelper } from '~/hooks/useSectionDeleteMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';

interface Props {
  section: Pick<Section, 'id' | 'title'>;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalSectionDelete: FC<Props> = ({ section, isOpen, onClose }) => {
  const router = useRouter();
  const { renderToastWith } = useToastHelper();
  const [sectionDeleteMutation] = useSectionDeleteMutationHelper();
  const sectionQuery = useListSectionsQuery();
  const collectionQuery = useCollectionsQuery();

  const deleteSection = () => {
    sectionDeleteMutation(
      {
        input: {
          id: section.id,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.deleteSectionSuccess,
          then: () => {
            sectionQuery.refetch();
            collectionQuery.refetch();
          },
        }),
        onError: renderToastWith({
          message: copy.deleteSectionError,
        }),
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Delete section</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete “<strong>{section.title}</strong>”?
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={deleteSection}>
            Yes, delete it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
