// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useSectionDeleteMutation } from '~/common/gql/section.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useSectionDeleteMutationHelper = createMutationHelperHook(
  useSectionDeleteMutation,
  {
    errorMessage: 'There was an error deleting this section: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.DELETE_SECTION,
        label: variables.input.id,
      }),
      afterMutation: (variables) => ({
        actionType: ActionType.SECTION_DELETED,
        label: variables.input.id,
      }),
    },
  },
);
