// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  IconButton,
  IconButtonProps,
  MenuButton,
} from '@chakra-ui/react';

export interface IconMenuButtonProps extends IconButtonProps {}

export const IconMenuButton = forwardRef<IconMenuButtonProps, 'button'>(
  (props, ref) => {
    return (
      <IconButton
        ref={ref}
        as={MenuButton}
        sx={{
          /* fixes alignment of children when as={MenuButton} */
          span: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...props?.sx?.['span'],
          },
          ...props?.sx,
        }}
        {...props}
      />
    );
  },
);
