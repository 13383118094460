// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

export const chromeExtUrl =
  'https://chrome.google.com/webstore/detail/save-to-niphtio/fkmalbfpcbjoplobjkfggafdkehffdff';

export const firefoxExtUrl =
  'https://addons.mozilla.org/en-US/firefox/addon/niphtio/';

export const appleStoreAppUrl =
  'https://apps.apple.com/us/app/niphtio/id6738670733';
