// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import {
  FlexProps,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';
import { Section } from '~/common/gql';
import { IconMenuButton } from '~/components/IconMenuButton';
import { ReactIcon } from '~/components/Icons';
import { useModalCollectionCreateContext } from '~/containers/common/Collection/ModalCollectionCreate/useModalCollectionCreateContext';
import { ModalSectionDeleteDisclosureContext } from '~/containers/common/Section/ModalSectionDelete/ModalSectionDeleteDisclosureContext';
import { ModalSectionRenameDisclosureContext } from '~/containers/common/Section/ModalSectionRename/ModalSectionRenameDisclosureContext';
import { DisclosureContextProvider } from './DisclosureContext/DisclosureContextProvider';
import { DisclosureContextTrigger } from './DisclosureContext/DisclosureContextTrigger';

interface Props extends FlexProps {
  section: Pick<Section, 'id' | 'title'>;
}

export const LeftSidebarSectionMenu = forwardRef<Props, 'div'>(
  ({ section }, ref) => {
    const modalCollectionCreateModal = useModalCollectionCreateContext();

    return (
      <Menu>
        <IconMenuButton
          as={MenuButton}
          variant="npGhost"
          size="sm"
          icon={<ReactIcon as={MdMoreVert} size="xs" />}
          aria-label={`${section.title} open menu`}
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              modalCollectionCreateModal.setSectionId(section.id);
              modalCollectionCreateModal.onOpen();
            }}
          >
            Create a collection
          </MenuItem>
          <DisclosureContextProvider>
            <DisclosureContextTrigger>
              <MenuItem>Rename section</MenuItem>
            </DisclosureContextTrigger>
            <ModalSectionRenameDisclosureContext section={section} />
          </DisclosureContextProvider>
          <DisclosureContextProvider>
            <DisclosureContextTrigger>
              <MenuItem color="red">Delete section</MenuItem>
            </DisclosureContextTrigger>
            <ModalSectionDeleteDisclosureContext section={section} />
          </DisclosureContextProvider>
        </MenuList>
      </Menu>
    );
  },
);
