// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useMemo } from 'react';
import { MdLabelOutline, MdPublic } from 'react-icons/md';
import { Collection } from '~/common/gql';
import { getIsSharedCollection } from '~/common/utilities/collection-utils/getIsSharedCollection';
import { ReactIcon, ReactIconProps } from './ReactIcon';

interface Props extends ReactIconProps {
  collection: Pick<Collection, 'sharingKey'>;
}

export const NpCollectionMorph = ({ collection, ...props }: Props) => {
  const shared = useMemo(() => getIsSharedCollection(collection), [collection]);
  return (
    <ReactIcon as={shared ? MdPublic : MdLabelOutline} size="xs" {...props} />
  );
};
