// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

export const isUrlHttpOrHttps = (candidateUrl: string) => {
  try {
    const url = new URL(candidateUrl);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {}

  return false;
};
