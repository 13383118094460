// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef, Link } from '@chakra-ui/react';
import { MdOpenInNew } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';

export const SearchOperatorLink = forwardRef<FlexProps, 'div'>(
  ({ ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        as={Link}
        alignItems="center"
        variant="npAccent"
        fontSize="sm"
        href="/guides/advanced-search-operators"
        gap={1}
        target="_blank"
        {...props}
      >
        Learn how to use advanced search filters
        <ReactIcon as={MdOpenInNew} size="xs" />
      </Flex>
    );
  },
);
