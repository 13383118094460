// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text, TextProps } from '@chakra-ui/react';

interface Props extends TextProps {}

export const FCBSearchHintText = forwardRef<Props, 'span'>(
  ({ ...props }, ref) => {
    return <Text ref={ref} color="npSubduedText" fontSize="sm" {...props} />;
  },
);
