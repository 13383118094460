// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const quietScrollBar = {
  width: '0.275em',
};

export const FlexWithQuietScrollBar = styled(Flex)`
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  visibility: hidden;

  & > *,
  &:hover,
  &:focus {
    visibility: visible;
  }
  &::-webkit-scrollbar {
    width: ${quietScrollBar.width};
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
  }
`;
