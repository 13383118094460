// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Box, forwardRef, Tooltip, TooltipProps } from '@chakra-ui/react';
import { useEffect, useId, useRef, useState } from 'react';
import { useIsOverflowing } from './useIsOverflowing';

export interface OverflowTooltipProps extends TooltipProps {
  overflowClassName: string;
}

/**
 * Renders tooltip if overflowing element overflows.
 */
export const OverflowTooltip = forwardRef<OverflowTooltipProps, 'div'>(
  ({ overflowClassName, children, ...props }, ref) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const classId = useId();
    const className = `tooltip-${classId}`;
    const [parent, setParent] = useState<Element>(null);
    const { x, y } = useIsOverflowing(overflowClassName, parent);

    useEffect(() => {
      if (!targetRef?.current) return;
      // WORKAROUND:
      // targetRef.current can be undefined even if the previous
      // reference is still valid, so use the last defined value
      // to get the parent.
      setParent(targetRef.current?.parentElement);
    }, [targetRef, className]);

    return (
      <>
        <Box
          as="span" // span so it can be nested in button
          // WORKAROUND:
          // Tooltip ref is undefined, so we use
          // an invisible div to find the parent.
          ref={targetRef}
          display="none"
        />
        <Tooltip
          ref={targetRef}
          className={className}
          isDisabled={!(x || y)}
          {...props}
        >
          {children}
        </Tooltip>
      </>
    );
  },
);
