// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  FormControl,
  FormLabel,
  Heading,
  Link,
  Stack,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';
import { isAndroidOs } from '~/common/utilities/browser-utils';
import { PageType } from '~/common/utilities/pageInfo';
import { isPWAInstalled } from '~/common/utilities/pwa-utils';
import { useLogout } from '~/hooks/useLogout';
import { InstallPWAAd } from '../Ads/InstallPWAAd/InstallPWAAd';

export const MoreDrawer = (props: Omit<DrawerProps, 'children'>) => {
  const [pwaInstalled, setPwaInstalled] = useState<boolean>(false);
  const isAndroid = isAndroidOs();
  const { logout } = useLogout();
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    const setPwaInstalledAsync = async () => {
      const _pwaInstalled = await isPWAInstalled().catch(() => false);
      setPwaInstalled(_pwaInstalled);
    };

    setPwaInstalledAsync();
  }, []);

  return (
    <Drawer variant="npDust" placement="right" size="xs" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader fontSize="xl" fontWeight="medium">
          More
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing={8}>
            <Stack spacing={3}>
              <Heading size="md">Account</Heading>
              <Stack spacing={2} mt={3}>
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FormLabel
                    htmlFor="dark-mode-toggle"
                    mb="0"
                    fontWeight="normal"
                  >
                    Dark mode
                  </FormLabel>
                  <Switch
                    id="dark-mode-toggle"
                    isChecked={colorMode === 'dark'}
                    onChange={toggleColorMode}
                  />
                </FormControl>
                <NextLink href={PageType.Settings} passHref legacyBehavior>
                  <Link variant="npAccent">Settings</Link>
                </NextLink>
                <NextLink href={PageType.Import} passHref legacyBehavior>
                  <Link variant="npAccent">Import</Link>
                </NextLink>
                <Link variant="npAccent" colorScheme="red" onClick={logout}>
                  Log out
                </Link>
              </Stack>
            </Stack>
            <Stack spacing={3}>
              <Heading size="md">Support</Heading>
              <Stack spacing={2}>
                <Link
                  variant="npAccent"
                  href={PageType.GuideCheckOffTodoItems}
                  isExternal
                >
                  Help guides
                </Link>
                <NextLink href={PageType.ContactUs} passHref legacyBehavior>
                  <Link variant="npAccent">Contact us</Link>
                </NextLink>
              </Stack>
            </Stack>
            {!pwaInstalled && isAndroid && <InstallPWAAd />}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
