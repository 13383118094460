// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useDisclosureContext } from './useDisclosureContext';

interface Props extends FlexProps {}

export const DisclosureContextTrigger: FC<Props> = ({ ...props }) => {
  const disclosure = useDisclosureContext();

  return (
    <Flex
      onClick={() => {
        disclosure.onOpen();
      }}
      {...props}
    />
  );
};
