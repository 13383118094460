// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { UseDisclosureReturn } from '@chakra-ui/react';
import { createContext } from 'react';

interface Props extends UseDisclosureReturn {}

export const DisclosureContext = createContext<Props>({
  isOpen: false,
  onOpen: function (): void {
    throw new Error('Function not implemented.');
  },
  onClose: function (): void {
    throw new Error('Function not implemented.');
  },
  onToggle: function (): void {
    throw new Error('Function not implemented.');
  },
  isControlled: false,
  getButtonProps: function (props?: any) {
    throw new Error('Function not implemented.');
  },
  getDisclosureProps: function (props?: any) {
    throw new Error('Function not implemented.');
  },
});
