// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  forwardRef,
} from '@chakra-ui/react';

export const NoticeJavascriptDisabled = forwardRef<AlertProps, 'div'>(
  (props, ref) => {
    return (
      <Alert
        ref={ref}
        flexDirection="column"
        variant="npNotice"
        alignItems="left"
        justifyContent="center"
        size="lg"
        {...props}
      >
        <AlertTitle>JavaScript required</AlertTitle>
        <AlertDescription>
          It looks like you don’t have JavaScript enabled. Please enable
          JavaScript on your browser and refresh this page to continue using
          Niphtio.
        </AlertDescription>
      </Alert>
    );
  },
);
