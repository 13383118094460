// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useSectionCreateMutation } from '~/common/gql/section.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useSectionCreateQueryHelper = createMutationHelperHook(
  useSectionCreateMutation,
  {
    errorMessage: 'There was an error creating this section: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.ADD_SECTION,
        label: variables.input.title,
      }),
      afterMutation: (variables, data) => ({
        actionType: ActionType.SECTION_ADDED,
        srcCollectionId: data?.sectionCreate?.id,
      }),
    },
  },
);
