// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import capitalize from 'lodash/capitalize';
import { parse } from 'tldts';

export const getUrlAbbreviation = (url: string): string => {
  const parsedUrl = parse(url);
  return capitalize(parsedUrl.domain?.slice(0, 1));
};
