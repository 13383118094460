// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';

interface Props extends ButtonProps {}

export const LeftSidebarButton = forwardRef<Props, 'button'>(
  ({ isActive, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className="niphtio-left-sidebar-button"
        size="sm"
        variant="npGhostDust"
        flexShrink={0}
        justifyContent="flex-start"
        fontWeight={!isActive ? 'medium' : undefined}
        w="100%"
        isActive={isActive}
        {...props}
      />
    );
  },
);
