// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  ChakraComponent,
  FlexProps,
  forwardRef,
  IconButton,
  Show,
  Stack,
  Text,
  ToastId,
  Tooltip,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { Toast } from '@niphtio/np-theme';
import React, { FC, useEffect, useState } from 'react';
import { MdClose, MdOutlineCloud, MdOutlineCloudOff } from 'react-icons/md';
import { ReactIcon } from '../Icons';

interface Props extends FlexProps {
  showIcon?: boolean;
}

const NetworkToast: ChakraComponent<'span', Props> = forwardRef(
  ({ children, showIcon = true, onClick, ...props }, ref) => {
    return (
      <Toast display="flex" {...props} ref={ref}>
        <Stack alignItems="center" direction="row">
          {children}
          {showIcon && (
            <IconButton
              display="flex"
              size="xs"
              aria-label="close"
              variant="unstyled"
              icon={<ReactIcon boxSize="5" as={MdClose} />}
              onClick={onClick as any}
            />
          )}
        </Stack>
      </Toast>
    );
  },
);

/**
 * Network connectivity component
 */
export const NetworkIndicator: FC = () => {
  const toast = useToast();
  const toastIdRef = React.useRef<ToastId>();
  const mobileToastRef = React.useRef<HTMLDivElement>();
  const [offline, setOffline] = useState(false);
  const isDesktop = useBreakpointValue({
    base: true,
    lg: true,
    md: true,
    sm: false,
  });

  useEffect(() => {
    const closeToast = () => {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current);
      }
    };

    const online = () => {
      closeToast();
      setOffline(false);

      toastIdRef.current = toast({
        duration: 10000,
        render: () => (
          <NetworkToast onClick={closeToast}>
            <MdOutlineCloud />
            <Text>Connection restored</Text>
          </NetworkToast>
        ),
      });
    };

    const offline = () => {
      closeToast();
      setOffline(true);

      if (isDesktop)
        toastIdRef.current = toast({
          duration: 10000,
          render: () => (
            <NetworkToast onClick={closeToast}>
              <MdOutlineCloudOff />
              <Text>No internet connection</Text>
            </NetworkToast>
          ),
        });
    };

    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Show below="md">
      <Box
        height={
          offline ? (mobileToastRef?.current?.offsetHeight || 0) + 'px' : 0
        }
      ></Box>
      <NetworkToast
        zIndex={10000}
        position="fixed"
        pointerEvents="none"
        ref={mobileToastRef}
        right={0}
        top={0}
        left={0}
        display="flex"
        opacity={offline ? 1 : 0}
        borderRadius={0}
        justifyContent="start"
        showIcon={false}
      >
        <MdOutlineCloudOff />
        <Text>No internet connection</Text>
      </NetworkToast>
    </Show>
  );
};

export const ConnectivityIconIndicator = () => {
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    const online = () => {
      setOffline(false);
    };

    const offline = () => {
      setOffline(true);
    };

    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    };
  }, []);

  return offline ? (
    <Show above="sm">
      <Tooltip label="No internet connection">
        <IconButton
          variant="ghost"
          aria-label="Offline"
          icon={<ReactIcon as={MdOutlineCloudOff} />}
        />
      </Tooltip>
    </Show>
  ) : null;
};
