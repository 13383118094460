// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { copy } from '~/common/copy/copy';
import { Section } from '~/common/gql';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import { useSectionUpdateMutationHelper } from '~/hooks/useSectionUpdateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';

interface Props {
  section: Pick<Section, 'id' | 'title'>;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalSectionRename: FC<Props> = ({
  section,
  isOpen,
  onClose: onCloseModal,
}: Props) => {
  const { renderToastWith } = useToastHelper();
  const [sectionUpdateMutation] = useSectionUpdateMutationHelper();
  const sectionQuery = useListSectionsQuery();
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      title: section.title,
    },
  });

  const {
    errors: { title: titleErrors },
  } = formState;

  /**
   * Resets the form and sets title to the value passed down by the component's parent.
   */
  const resetForm = useCallback(() => {
    reset({ title: section.title });
  }, [reset, section.title]);

  const onClose = () => {
    resetForm();
    onCloseModal();
  };

  const onUpdateSubmit = ({ title }) => {
    sectionUpdateMutation(
      {
        input: {
          find: {
            id: section.id,
          },
          title: {
            value: title,
          },
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.renameSectionSuccess,
          then: () => {
            sectionQuery.refetch();
            onCloseModal();
          },
        }),
        onError: (e) => {
          const errors = e?.graphQLErrors
            ?.map((it) => it.exception?.detailMessage ?? it.message)
            .join('\n');

          renderToastWith({
            message: errors ?? copy.renameSectionError,
          })();
        },
      },
    );
  };

  useEffect(() => {
    // if title changes
    // reset the form
    resetForm();
  }, [section.title, resetForm]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onUpdateSubmit)}>
        <ModalHeader textAlign="center">Rename section</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!titleErrors}>
            <Input
              variant="npFlushed"
              aria-label="title"
              name="title"
              type="text"
              placeholder="Title"
              textAlign="center"
              autoComplete="off"
              autoFocus
              isRequired
              {...register('title', { required: 'Tag title is required' })}
            />
            <FormErrorMessage>{titleErrors?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter
          w="full"
          flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button variant="npPrimary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
