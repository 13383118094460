// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { copy } from '~/common/copy/copy';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import { useSectionCreateQueryHelper } from '~/hooks/useSectionCreateMutationHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useModalSectionCreateContext } from './useModalSectionCreateContext';

export const ModalSectionCreate: FC = () => {
  const modal = useModalSectionCreateContext();
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      title: '',
    },
  });
  const [createSection] = useSectionCreateQueryHelper();
  const sectionsQuery = useListSectionsQuery();
  const { renderToastWith } = useToastHelper();

  const {
    errors: { title: titleErrors },
  } = formState;

  const onClose = () => {
    reset();
    modal.onClose();
  };

  const onSubmit = ({ title }) => {
    createSection(
      {
        input: { title },
      },
      {
        onSuccess: renderToastWith({
          message: copy.createSectionSuccess,
          then: () => {
            onClose();
            sectionsQuery.refetch();
          },
        }),
        onError: (e) => {
          const errors = e?.graphQLErrors
            ?.map((it) => it.exception?.detailMessage ?? it.message)
            .join('\n');

          renderToastWith({
            message: errors ?? copy.createSectionError,
          })();
        },
      },
    );
  };

  return (
    <Modal isOpen={modal.isOpen} onClose={onClose} size="xs" isCentered>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader textAlign="center">New Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!titleErrors}>
            <Input
              variant="npFlushed"
              aria-label="title"
              name="title"
              type="text"
              placeholder="Title"
              textAlign="center"
              autoComplete="off"
              autoFocus
              isRequired
              {...register('title', { required: 'Title is required' })}
            />
            <FormErrorMessage>{titleErrors?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="npPrimary" type="submit">
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
