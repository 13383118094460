// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import NextLink from 'next/link';
import { MdMoreHoriz, MdOutlineBook, MdSearch } from 'react-icons/md';
import { PageType } from '~/common/utilities/pageInfo';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { useAppMenu } from '~/containers/common/App/useAppMenu';
import { useAppContext } from '~/containers/common/AppContext';
import { BottomTabButton } from './BottomTabButton';

interface Props extends FlexProps {}

export const BottomTabBar = forwardRef<Props, 'div'>((props, ref) => {
  const { searchModal, moreDrawer } = useAppContext();
  const { isNavItemActive } = useAppMenu();

  return (
    <Flex
      ref={ref}
      w="100%"
      borderTopWidth="1px"
      borderColor="npGray.400"
      bg="npBrandedBg"
      {...props}
    >
      <NextLink href={PageType.Tag} passHref legacyBehavior>
        <BottomTabButton
          as="a"
          leftIcon={<ReactIcon as={MdOutlineBook} />}
          isActive={isNavItemActive('library')}
          onClick={searchModal.onClose}
        >
          Library
        </BottomTabButton>
      </NextLink>
      <BottomTabButton
        leftIcon={<ReactIcon as={MdSearch} />}
        isActive={isNavItemActive('search')}
        onClick={() => {
          searchModal.onOpen();
        }}
      >
        Search
      </BottomTabButton>
      <BottomTabButton
        leftIcon={<ReactIcon as={MdMoreHoriz} />}
        isActive={false}
        onClick={() => {
          searchModal.onClose();
          moreDrawer.onOpen();
        }}
      >
        More
      </BottomTabButton>
    </Flex>
  );
});
