// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const LeftSidebarGroup = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        as="section"
        direction="column"
        p={2}
        borderRadius="md"
        bg="npBrandedBg"
        gap={
          undefined /* leave this undefined to prevent child collapse component from lagging */
        }
        {...props}
      />
    );
  },
);
