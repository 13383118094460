// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Flex,
  FlexProps,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';

interface Props extends FlexProps {}

export const WideCardFlexNote = forwardRef<Props, 'div'>((props, ref) => {
  const hoverBg = useColorModeValue('white', '#1A202C');
  return (
    <Flex
      ref={ref}
      borderRadius="md"
      p={1}
      alignContent="center"
      cursor="pointer"
      {...props}
      _hover={{
        bg: props?._hover?.bg ?? hoverBg,
        ...props?._hover,
      }}
    />
  );
});
