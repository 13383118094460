// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

interface Props {
  substring?: string;
  // if true, result will return true if substring is empty
  matchEmpty?: boolean;
}

export const getIsTodoMatchedSubstring = ({
  substring,
  matchEmpty = true,
}: Props): boolean => {
  const isSearchTermEmpty = isEmpty(trim(substring));

  const isMatch =
    (matchEmpty && isSearchTermEmpty) ||
    /** matches to do substring ignoring case and white space
     * e.g. 'to do', 'ToDo"
     */
    (!isSearchTermEmpty &&
      'todo'.includes(trim(substring).toLowerCase().split(' ').join('')));

  return isMatch;
};
