// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpNote = (props: ReactIconProps) => (
  <ReactIcon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9337 11.5H17.9337V5.5H3.93375V9.5V19.5H10.9337V21.5H3.93375C2.82375 21.5 1.93375 20.6 1.93375 19.5L1.94375 5.5C1.94375 4.4 2.82375 3.5 3.93375 3.5H17.9337C19.0337 3.5 19.9337 4.4 19.9337 5.5V11.5ZM21.0637 16.49L21.7737 15.78C22.1637 15.39 22.1637 14.76 21.7737 14.37L21.0637 13.66C20.6737 13.27 20.0437 13.27 19.6537 13.66L18.9437 14.37L21.0637 16.49ZM20.3537 17.2L18.2337 15.08L12.9337 20.38V22.5H15.0537L20.3537 17.2ZM6 11.5H16V13.5H13.7273H6V11.5ZM6 7.5H16V9.5H6V7.5ZM12.3636 17.5H6V15.5H12.3636V17.5Z"
      fill="currentColor"
    />
  </ReactIcon>
);
