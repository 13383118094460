// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text, TextProps } from '@chakra-ui/react';

interface Props extends TextProps {}

/**
 * Provides a preview of text with the rest hidden by an ellipsis.
 */
export const PreviewText = forwardRef<Props, 'p'>((props, ref) => {
  return (
    <Text
      ref={ref}
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      {...props}
    />
  );
});
