// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Heading } from '@chakra-ui/react';
import { NpAdd } from '~/components/Icons/NpAdd';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { FocusableCardBody } from './FocusableCardBody';

export interface FCBAddUrlProps {
  url: string;
}

export const FCBAddUrl = forwardRef<FCBAddUrlProps, 'div'>(({ url }, ref) => {
  return (
    <FocusableCardBody>
      <ReactIcon as={NpAdd} size="xs" />
      <Heading size="xs" wordBreak={'break-all'}>
        Add &quot;{url}&quot;
      </Heading>
    </FocusableCardBody>
  );
});
