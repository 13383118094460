// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons';

interface Props extends ButtonProps {
  isOpen: boolean;
}

export const LeftSidebarGroupHeaderButton = forwardRef<Props, 'button'>(
  ({ isOpen, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="link"
        size="md"
        leftIcon={
          <ReactIcon as={isOpen ? MdArrowDropDown : MdArrowRight} size="sm" />
        }
        fontWeight="semibold"
        justifyContent="stretch"
        color="gray.800"
        _dark={{ color: 'whiteAlpha.900' }}
        {...props}
      />
    );
  },
);
