// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { DisclosureContext } from './DisclosureContext';

export const DisclosureContextProvider: FC<PropsWithChildren> = ({
  ...props
}) => {
  const disclosure = useDisclosure();

  return <DisclosureContext.Provider value={disclosure} {...props} />;
};
