// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useSectionUpdateMutation } from '~/common/gql/section.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { createMutationHelperHook } from './createMutationHelperHook';

export const useSectionUpdateMutationHelper = createMutationHelperHook(
  useSectionUpdateMutation,
  {
    errorMessage: 'There was an error updating this section: ',
    log: {
      beforeMutation: (variables) => ({
        actionType: ActionType.UPDATE_SECTION,
        label: variables.input.title.value,
        srcCollectionId: variables.input.find.id,
      }),
      afterMutation: (variables, data) => ({
        actionType: ActionType.SECTION_UPDATED,
        srcCollectionId: variables.input.find.id,
        label: data?.sectionUpdate?.id,
      }),
    },
  },
);
