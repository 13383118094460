// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  condition: boolean;
  wrapWith: (chidren) => JSX.Element;
}

export const IfWrap: FC<Props> = ({
  condition,
  wrapWith: Wrapper,
  children,
}) => {
  if (condition) return Wrapper(children);

  return <>{children}</>;
};
