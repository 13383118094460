// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { AppContext } from './AppContext';

export const useAppContext = () => {
  const props = useContext(AppContext);

  return props;
};
