// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import first from 'lodash/first';
import { useEffect, useState } from 'react';

/**
 * Calculates whether the first element with class name in parent
 * is overflowing on the x and y axis.
 *
 * @param parent      Element to look for child with `classSelector`.
 * @param className   Reference class name of the child element that
 *                    may overflow.
 *                    Example: 'overflow-title-123'
 *                    If there are multiple elements, only the first one
 *                    is selected.
 * @returns For x, y: True, if element overflows.
 */
export const useIsOverflowing = (
  classSelector: string | undefined,
  parent: Element | undefined,
) => {
  const [htmlElement, setHtmlElement] = useState<Element>();
  const isOverflowing = getIsOverflowing(htmlElement);
  const [x, setX] = useState(isOverflowing.x);
  const [y, setY] = useState(isOverflowing.y);

  useEffect(() => {
    const matches = parent?.getElementsByClassName(classSelector);
    const element = first(matches);
    if (!element) return;
    setHtmlElement(element);
  }, [parent, classSelector]);

  useEffect(() => {
    if (!htmlElement) return;
    let resizeObserver = null;
    try {
      // browser versions pre-2020 may not support resize observer
      resizeObserver = new ResizeObserver(() => {
        const overflowing = getIsOverflowing(htmlElement);
        setX(overflowing.x);
        setY(overflowing.y);
      });
    } catch {}
    resizeObserver?.observe(htmlElement);
    return () => {
      resizeObserver?.unobserve(htmlElement);
    };
  }, [htmlElement]);

  return {
    x,
    y,
  };
};

const getIsOverflowing = (htmlElement: Element) => {
  return {
    x: htmlElement && htmlElement.scrollWidth > htmlElement.clientWidth,
    y: htmlElement && htmlElement.scrollHeight > htmlElement.clientHeight,
  };
};
