// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { copy } from '~/common/copy/copy';
import ternaryIf from '~/common/utilities/array-utils/ternaryIf';
import { getIsSharedCollection } from '~/common/utilities/collection-utils/getIsSharedCollection';
import { PageType } from '~/common/utilities/pageInfo';
import { isUrlHttpOrHttps } from '~/common/utilities/url-utils/isUrlHttpOrHttps';
import { NpAdd } from '~/components/Icons/NpAdd';
import { NpCollection } from '~/components/Icons/NpCollection';
import { NpSharedCollection } from '~/components/Icons/NpSharedCollection';
import { NpTodo } from '~/components/Icons/NpTodo';
import { filterCollectionByTitleSubstring } from '~/containers/common/Collection/filterCollectionByTitleSubstring';
import { getEnhancedCollections } from '~/containers/common/Collection/getEnhancedCollection';
import { getIsTodoMatchedSubstring } from '~/containers/common/Todo/getIsTodoMatchedSubstring';
import { useCollectionCreateQueryHelper } from '~/hooks/useCollectionCreateMutationHelper';
import { useCollectionsQueryHelper } from '~/hooks/useCollectionsQueryHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { FCBCollectionProps } from './FocusableCard/FCBCollection';
import { FocusableProps } from './FocusableCard/FocusableProps';

interface Props {
  namespace?: string;
  searchTerm: string;
  dismiss: VoidFunction;
}

interface FocusableSearchCollectionProps
  extends FocusableProps,
    FCBCollectionProps {}

export const useSearchCollections = ({
  namespace = 'collection',
  searchTerm,
  dismiss,
}: Props): FocusableSearchCollectionProps[] => {
  const router = useRouter();
  const { userCollections, userStandardCollections } =
    useCollectionsQueryHelper();
  const { renderToastWith } = useToastHelper();

  const [createCollection] = useCollectionCreateQueryHelper();

  const enhancedCollection = getEnhancedCollections({
    all: [...userCollections, ...userStandardCollections],
    selected: [],
  });

  const filteredCollections = filterCollectionByTitleSubstring({
    substring: searchTerm,
    collections: enhancedCollection,
  });

  const isTodoMatch = getIsTodoMatchedSubstring({
    substring: searchTerm,
    matchEmpty: false,
  });

  const searchIsEmpty = isEmpty(searchTerm?.trim());

  const noExactMatchTag =
    !searchIsEmpty && // doesn't count if there is no search term
    !isUrlHttpOrHttps(searchTerm) && // exclude urls
    !filteredCollections.find(
      (tag) => tag.title?.toLocaleLowerCase() == searchTerm.toLocaleLowerCase(),
    );

  const createTagItem = {
    key: `${namespace}:${searchTerm}`,
    title: `Create new collection for "${searchTerm}"`,
    iconType: NpAdd,
    onClick: () => {
      createCollection({ input: { title: searchTerm } });
      renderToastWith({
        message: copy.createCollectionSuccess,
      })();
    },
  };

  const todoItem = {
    key: `${namespace}:to-do`,
    title: 'To do',
    iconType: NpTodo,
    onClick: () => {
      router.push(`${PageType.Todo}`);
      dismiss(); // required to dismiss popup if we are currently on the page
    },
  };

  return [
    ...ternaryIf(noExactMatchTag, [createTagItem], []),
    ...ternaryIf(isTodoMatch, [todoItem], []),
    ...ternaryIf(
      !isEmpty(filteredCollections),
      filteredCollections?.map((it) => {
        const key = `${namespace}:${it.id}`;
        return {
          key,
          title: it.title,
          iconType: getIsSharedCollection(it)
            ? NpSharedCollection
            : NpCollection,
          onClick: () => {
            router.push(`${PageType.Tag}/${it.id}`);
            dismiss(); // required to dismiss popup if we are currently on the page
          },
        } satisfies FocusableSearchCollectionProps;
      }),
      [],
    ),
  ];
};
