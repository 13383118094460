// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import router from 'next/router';
import { Item } from '~/common/gql';
import { PageType } from '~/common/utilities/pageInfo';

type UrlObject = Exclude<Parameters<typeof router.push>[0], string>;

/**
 * Creates a path to the item page with query parameters
 * that gives the item page information it needs to load
 * the page properly.
 */
export const getItemPath = (item: Pick<Item, 'id'>): UrlObject => {
  if (!item?.id) return;

  return {
    pathname: `${PageType.Reader}/${item.id}`,
  };
};

export const gotoReader = ({
  openItem: item,
}: {
  openItem: Pick<Item, 'id'>;
}) => {
  const path = getItemPath(item);

  if (!path) return;
  router.push(path);
};
