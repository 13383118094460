// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text } from '@chakra-ui/react';
import { TagBadge } from '@niphtio/np-theme';

export interface Props {
  collections: string[];
}

const maxCollections = 2;

export const FCBItemCollectionBlock = forwardRef<Props, 'div'>(
  ({ collections }, ref) => {
    return (
      <>
        {collections?.slice(0, maxCollections + 1).map((it, idx) => (
          <TagBadge key={it}>
            <Text
              fontSize="smaller"
              whiteSpace="nowrap"
              maxWidth="70px"
              noOfLines={1}
            >
              {idx < maxCollections
                ? it
                : `+${collections.length - maxCollections}`}
            </Text>
          </TagBadge>
        ))}
      </>
    );
  },
);
