// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { createContext } from 'react';

interface SearchContextProps {
  searchTerm?: string;
  setSearchTerm: (nextSearchTerm: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({
  searchTerm: '',
  setSearchTerm: (_: string) => void 0,
});
