// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, forwardRef, Kbd, KbdProps } from '@chakra-ui/react';

interface Props extends KbdProps {}

export const FCBSearchHintKbd = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    return (
      <Flex ref={ref} alignItems="center">
        <Kbd variant="npBranded" {...props} />
      </Flex>
    );
  },
);
