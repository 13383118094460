// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { DisclosureContext } from './DisclosureContext';

export const useDisclosureContext = () => {
  const props = useContext(DisclosureContext);

  return props;
};
