// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useModalSectionCreateContext } from './useModalSectionCreateContext';

interface Props extends FlexProps {}

export const ModalSectionCreateContextTrigger: FC<Props> = ({ ...props }) => {
  const disclosure = useModalSectionCreateContext();

  return (
    <Flex
      onClick={() => {
        disclosure.onOpen();
      }}
      {...props}
    />
  );
};
