// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { ModalCollectionCreateContext } from './ModalCollectionCreateContext';

export const useModalCollectionCreateContext = () => {
  const props = useContext(ModalCollectionCreateContext);

  return props;
};
