// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef, TooltipProps } from '@chakra-ui/react';
import { quietScrollBar } from '~/components/FlexWithQuietScrollBar/FlexWithQuietScrollBar';
import { LeftSidebarFollowingGroup } from './LeftSidebarFollowingGroup/LeftSidebarFollowingGroup';
import { LeftSidebarMyCollectionsGroup } from './LeftSidebarMyCollectionsGroup/LeftSidebarMyCollectionsGroup';

interface Props extends FlexProps {
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebar = forwardRef<Props, 'div'>(
  ({ tooltipPlacement, ...props }, ref) => {
    return (
      <Flex
        as="nav"
        aria-label="Main navigation"
        direction="column"
        gap={quietScrollBar.width}
        // <margin note>
        // mr is excluded because the quiet scroll bar already adds margin to the right
        my={quietScrollBar.width}
        ml={quietScrollBar.width}
        // <./margin note>
        w="100%"
        overflowX="hidden" // prevents LeftSidebarGroup border radius from getting cut off on X overflow
        h="fit-content" // prevents hidden y overflow
      >
        <LeftSidebarMyCollectionsGroup tooltipPlacement={tooltipPlacement} />
        <LeftSidebarFollowingGroup tooltipPlacement={tooltipPlacement} />
      </Flex>
    );
  },
);
