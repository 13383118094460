// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { UseDisclosureReturn } from '@chakra-ui/react';
import { createContext } from 'react';

/**
 * Used to manage UI components in /app/** pages.
 */
export interface ModalSectionCreateContextProps extends UseDisclosureReturn {}

export const ModalSectionCreateContext =
  createContext<ModalSectionCreateContextProps>({
    isOpen: false,
    onOpen: function (): void {
      throw new Error('Function not implemented.');
    },
    onClose: function (): void {
      throw new Error('Function not implemented.');
    },
    onToggle: function (): void {
      throw new Error('Function not implemented.');
    },
    isControlled: false,
    getButtonProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
    getDisclosureProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
  });
