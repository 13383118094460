// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useApolloClient } from '@apollo/client';
import {
  Collapse,
  Flex,
  FlexProps,
  forwardRef,
  TooltipProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useFollowListQuery } from '~/common/gql/collection.generated';
import {
  UserPreferenceDocument,
  useUpdatePreferenceMutation,
  useUserPreferenceQuery,
} from '~/common/gql/user.generated';
import { PageType } from '~/common/utilities/pageInfo';
import { NpCollectionMorph } from '~/components/Icons/NpCollectionMorph';
import { LeftSidebarButtonOverflow } from '~/containers/common/App/LeftSidebar/LeftSidebarButtonOverflow';
import { LeftSidebarGroup } from '~/containers/common/App/LeftSidebar/LeftSidebarGroup';
import { LeftSidebarGroupHeader } from '~/containers/common/App/LeftSidebar/LeftSidebarGroupHeader';
import { LeftSidebarGroupHeaderButton } from '~/containers/common/App/LeftSidebar/LeftSidebarGroupHeaderButton';
import { useAppMenu } from '~/containers/common/App/useAppMenu';

interface Props extends FlexProps {
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebarFollowingGroup = forwardRef<Props, 'div'>(
  ({ tooltipPlacement, ...props }, ref) => {
    const apolloClient = useApolloClient();
    const followListQuery = useFollowListQuery();
    const followingCollections = followListQuery.data?.collectionFollowsList;
    const userPreferenceQuery = useUserPreferenceQuery();
    const [userPreferenceMutation] = useUpdatePreferenceMutation();
    const { isSharedItemActive } = useAppMenu();
    const isOpen = !(
      userPreferenceQuery.data?.userPreference?.collapseFollowingCollections ??
      false
    );
    const onToggleCollapse = () => {
      const userPreference = userPreferenceQuery.data?.userPreference;
      userPreferenceMutation({
        variables: {
          input: {
            collapseFollowingCollections: isOpen,
          },
        },
      });
      if (userPreference) {
        // update the cache immediately
        // don't use optimistic update because the request is not guaranteed to
        // be successful and we want user changes to persist even if the
        // request fails, for example: if the user or server is offline
        const documentNode = UserPreferenceDocument;

        apolloClient.cache.writeQuery({
          query: documentNode,
          overwrite: true,
          data: {
            userPreference: {
              ...userPreference,
              collapseFollowingCollections: isOpen,
            },
          },
        });
      }
    };

    if (!followingCollections || !followingCollections.length) {
      return <></>;
    }
    return (
      <LeftSidebarGroup>
        <LeftSidebarGroupHeader>
          <Flex as="h2">
            <LeftSidebarGroupHeaderButton
              isOpen={isOpen}
              onClick={onToggleCollapse}
            >
              Following
            </LeftSidebarGroupHeaderButton>
          </Flex>
        </LeftSidebarGroupHeader>
        <Collapse in={isOpen} animateOpacity>
          <Flex direction="column">
            {followingCollections?.map((it) => {
              return (
                <LeftSidebarButtonOverflow
                  key={it.sharingKey}
                  as={NextLink}
                  oid={it.sharingKey}
                  href={`${PageType.SharedCollectionPrefix}/${it.sharingKey}`}
                  leftIcon={<NpCollectionMorph collection={it} />}
                  isActive={isSharedItemActive(it.sharingKey)}
                  label={it.title}
                  tooltipPlacement={tooltipPlacement}
                  textAlign="start"
                  h={14}
                >
                  {it.title}
                  <br />
                  {it.creatorName}
                </LeftSidebarButtonOverflow>
              );
            })}
          </Flex>
        </Collapse>
      </LeftSidebarGroup>
    );
  },
);
