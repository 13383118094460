// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { ButtonProps, forwardRef, TooltipProps } from '@chakra-ui/react';
import { Collection } from '~/common/gql';
import { getIsSharedCollection } from '~/common/utilities/collection-utils/getIsSharedCollection';
import { NpCollection } from '~/components/Icons/NpCollection';
import { NpSharedCollection } from '~/components/Icons/NpSharedCollection';
import { OverflowTooltip } from '~/components/OverflowTooltip';
import { PreviewText } from '~/components/PreviewText/PreviewText';
import { LeftSidebarButton } from './LeftSidebarButton';

interface Props extends ButtonProps {
  collection: Collection;
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebarCollectionButton = forwardRef<Props, 'button'>(
  ({ collection, tooltipPlacement, isActive, ...props }, ref) => {
    const overflowClassName = `mmlist-collection-${collection.id}`;
    return (
      <LeftSidebarButton
        ref={ref}
        leftIcon={
          getIsSharedCollection(collection) ? (
            <NpSharedCollection size="xs" />
          ) : (
            <NpCollection size="xs" />
          )
        }
        isActive={isActive}
        {...props}
      >
        <OverflowTooltip
          placement={tooltipPlacement}
          overflowClassName={overflowClassName}
          label={collection.title}
        >
          <PreviewText
            className={overflowClassName}
            data-dd-privacy="mask"
            data-list-title
          >
            {collection.title}
          </PreviewText>
        </OverflowTooltip>
      </LeftSidebarButton>
    );
  },
);
