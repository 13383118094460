// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { ModalSectionCreateContext } from './ModalSectionCreateContext';

export const useModalSectionCreateContext = () => {
  const props = useContext(ModalSectionCreateContext);

  return props;
};
