// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren, useState } from 'react';
import { Section } from '~/common/gql';
import { ModalCollectionCreateContext } from './ModalCollectionCreateContext';

export const ModalCollectionCreateContextProvider: FC<PropsWithChildren> = ({
  ...props
}) => {
  const [sectionId, setSectionId] = useState<Section['id'] | undefined | null>(
    null,
  );
  const disclosure = useDisclosure();

  return (
    <ModalCollectionCreateContext.Provider
      value={{
        ...disclosure,
        sectionId,
        setSectionId,
      }}
      {...props}
    />
  );
};
