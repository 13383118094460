// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Box, Flex, Tag } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { FC, useEffect, useState } from 'react';
import { default as ReactInfiniteScroll } from 'react-infinite-scroll-component';
import { InfiniteScrollViewProps } from './InfiniteScrollViewProps';

export const InfiniteScrollView: FC<InfiniteScrollViewProps> = ({
  count,
  loading,
  hasMore,
  scrollableId,
  onNextPage,
  children,
  ...boxProps
}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const onResize = () => {
      if (windowHeight === window.innerHeight) return;
      setWindowHeight(window.innerHeight);
    };

    const debounceOnResize = debounce(onResize, 250);
    window.addEventListener('resize', debounceOnResize);

    return () => window.removeEventListener('resize', debounceOnResize);
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Loads more pages if the ReactInfiniteScroll is not scrollable.
     * NOTE: This is a hotfix for a bug in ReactInfiniteScroll.
     * ReactInfiniteScroll will not trigger the next page if its
     * contents aren't scrollable even if there are more pages.
     */
    const loadMoreIfCantScroll = () => {
      const scrollElement =
        document.getElementById(scrollableId) ?? global.document?.body;

      const scrollHeight = scrollElement.scrollHeight;

      const maxBottomWhitespace = window.innerHeight;

      if (!hasMore) return;
      // if component is not scrollable, then it has no items
      if (!scrollHeight) return;
      // must account for potential whitespace (padding, margins)
      // within the scroll view and children
      if (scrollHeight > window.innerHeight + maxBottomWhitespace) return;
      onNextPage();
    };

    loadMoreIfCantScroll();
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHeight, count, hasMore]);

  return (
    <Box {...boxProps}>
      <ReactInfiniteScroll
        style={{ overflow: 'visible' }}
        dataLength={count}
        next={onNextPage}
        hasMore={hasMore}
        loader={
          loading &&
          count && (
            <Flex justifyContent="center" my="4">
              <Tag>Loading more...</Tag>
            </Flex>
          )
        }
        scrollableTarget={scrollableId}
      >
        {children}
      </ReactInfiniteScroll>
    </Box>
  );
};
