// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LeftSidebar } from './LeftSidebar';

export const LeftSidebarDrawer = (props: Omit<DrawerProps, 'children'>) => {
  const { events } = useRouter();
  const { isOpen, onClose } = props;

  useEffect(() => {
    const routeChangeHandler = () => {
      if (!isOpen) return;
      onClose();
    };

    events.on('routeChangeStart', routeChangeHandler);

    return () => {
      events.off('routeChangeStart', routeChangeHandler);
    };
  }, [events, isOpen, onClose]);

  return (
    <Drawer variant="npDust" placement="left" {...props}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader />
        <DrawerBody p={3}>
          <LeftSidebar tooltipPlacement="top-end" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
