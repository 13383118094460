// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const LeftSidebarSectionContainer = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    return <Flex ref={ref} as="section" direction="column" {...props} />;
  },
);
