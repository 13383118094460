// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { chromeExtUrl, firefoxExtUrl } from '~/common/links';
import { PageType } from '~/common/utilities/pageInfo';
import { FancyButton } from '~/components/FancyComponents';
import { ReactIcon } from '~/components/Icons';
import { NpPartyEmoji } from '~/components/Icons/NpPartyEmoji';
import { useOnboardingHint } from './useOnboardingHint';

export const OnboardingWelcomeMessageModal: FC<
  Partial<Omit<ModalProps, 'children'>>
> = (props) => {
  const tip = useOnboardingHint('hideWelcomeMessage');

  return (
    <Modal
      size={['xs', 'xs', 'lg']}
      isCentered
      isOpen={tip.isOpen}
      closeOnOverlayClick={false}
      onClose={tip.onClose}
      {...props}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={tip.onClose} />
        <ModalBody pt={9} px={9}>
          <Stack alignItems="center" textAlign="center">
            <NpPartyEmoji w={41} h={43} />
            <Text fontSize="lg" fontWeight="medium" data-onboarding>
              Welcome to Niphtio!
            </Text>
            <Text fontSize="sm">
              Time to save web pages and actually remember to read them.
            </Text>
            <Divider />
            <Text fontSize="sm">
              To save and organize web pages faster, try one of our extensions:
            </Text>
            <Flex direction={['column', 'column', 'row']}>
              <Link href={chromeExtUrl} target="_blank">
                <FancyButton
                  variant="npGhostDust"
                  size="sm"
                  leftIcon={<ReactIcon as={MdOpenInNew} size="xs" />}
                  desktopOnly
                >
                  Chrome extension
                </FancyButton>
              </Link>
              <Link href={firefoxExtUrl} target="_blank">
                <FancyButton
                  variant="npGhostDust"
                  size="sm"
                  leftIcon={<ReactIcon as={MdOpenInNew} size="xs" />}
                  desktopOnly
                >
                  Firefox extension
                </FancyButton>
              </Link>
              <Link href={PageType.GuideHowToUseTheBookmarklet} target="_blank">
                <Button
                  variant="npGhostDust"
                  size="sm"
                  leftIcon={<ReactIcon as={MdOpenInNew} size="xs" />}
                >
                  Bookmarklet
                </Button>
              </Link>
              <Link href={PageType.Pwa} target="_blank">
                <FancyButton
                  variant="npGhostDust"
                  size="sm"
                  leftIcon={<ReactIcon as={MdOpenInNew} size="xs" />}
                  mobileOnly
                >
                  PWA (Android)
                </FancyButton>
              </Link>
            </Flex>
          </Stack>
        </ModalBody>

        <ModalFooter justifyContent="center" pb={6}>
          <Button variant="npPrimary" size="sm" onClick={tip.onClose}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
