// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { isEmpty, isUndefined, trim } from 'lodash';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { getItemTitle } from '~/common/utilities/item-utils/item';
import { gotoReader } from '~/containers/pages/ReaderPage/helpers';
import { useItemsSearchQueryPagingHelper } from '~/hooks/useItemsSearchQueryPagingHelper';
import { FCBItemProps } from './FocusableCard/FCBItem/FCBItem';
import { FocusableProps } from './FocusableCard/FocusableProps';

interface Props {
  namespace?: string;
  searchTerm: string;
}

interface FocusableItemFoundProps extends FCBItemProps, FocusableProps {}

export const useSearchItems = ({
  namespace = 'item',
  searchTerm,
}: Props): [
  ReturnType<typeof useItemsSearchQueryPagingHelper>,
  FocusableItemFoundProps[],
] => {
  const [prevTerm, setPrevTerm] = useState(undefined);
  const options = useItemsSearchQueryPagingHelper({
    searchTerm,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { search } = options;

  const debounceSearch = useMemo(
    () =>
      debounce((_searchTerm) => {
        search({ searchTerm: _searchTerm });
      }, 600),
    // TODO: Fix warning appropriately then remove the line below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const isNoPreviousSearchTerm = isUndefined(prevTerm);
    const isSearchTermEmpty = isEmpty(trim(searchTerm));
    const isSeachTermChanged = prevTerm !== searchTerm;
    if (
      // if the search term has been set and changed
      (!isNoPreviousSearchTerm && isSeachTermChanged) ||
      // if the search term has not been set and is not empty
      (isNoPreviousSearchTerm && !isSearchTermEmpty)
    ) {
      setPrevTerm(searchTerm);
      // update item search every time the search filter changes
      debounceSearch(searchTerm);
    }
  }, [debounceSearch, prevTerm, searchTerm]);

  return [
    options,
    options.items?.map((it) => {
      const key = `${namespace}:${it.id}`;
      return {
        key,
        title: getItemTitle(it),
        imageSrc: it.urlData?.imageSrc,
        url: it.url,
        note: it.note,
        collections: it.collection?.map((it) => it.title),
        onClick: () => {
          gotoReader({
            openItem: it,
          });
        },
      } satisfies FocusableItemFoundProps;
    }) ?? [],
  ];
};
