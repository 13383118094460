// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Card, CardProps, forwardRef } from '@chakra-ui/react';

export interface FocusableCardProps extends CardProps {
  focused: boolean;
}

export const FocusableCard = forwardRef<FocusableCardProps, 'div'>(
  ({ focused, ...props }, ref) => {
    return (
      <Card
        ref={ref}
        size="sm"
        cursor="pointer"
        variant={focused ? 'npSearchItemFocused' : 'npSearchItem'}
        {...props}
      />
    );
  },
);
