// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { FlexProps, forwardRef } from '@chakra-ui/react';
import { mainMenuMobileHeight } from '~/common/uiTokens';
import { FancyFlex } from '~/components/FancyComponents';

interface Props extends FlexProps {}

/**
 * Space on the bottom that's available on mobile only.
 */
export const BottomSideLayout = forwardRef<Props, 'div'>((props, ref) => {
  return (
    <FancyFlex
      ref={ref}
      flex="0 0 auto"
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      w={'100vw'}
      exactHeight={mainMenuMobileHeight}
      overflow="hidden"
      {...props}
      mobileOnly
    />
  );
});
