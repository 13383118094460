// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useEffect } from 'react';
import { UserPreferenceInput } from '~/common/gql';
import { useUserPreferenceQuery } from '~/common/gql/user.generated';
import { useOnboardingHintBase } from './useOnboardingHintBase';

type PreferenceType = keyof Pick<
  UserPreferenceInput,
  'hideWelcomeMessage' | 'hideCardTodoMarkDoneHint' | 'hideCardManageTagsHint'
>;

export const useOnboardingHint = (preferenceType: PreferenceType) => {
  const { isOpen, onOpen, onClose } = useOnboardingHintBase(preferenceType);
  const { data, loading, error, called } = useUserPreferenceQuery();

  useEffect(() => {
    if (!loading && called && !error) {
      // hide onboarding hints until we receive the user's preferences
      const isNotHiddenRemotely =
        data?.userPreference?.[preferenceType] === false;

      if (isNotHiddenRemotely) {
        // only show hint if it's not set to hidden remotely
        onOpen();
        return;
      }
    }
  }, [called, data?.userPreference, error, loading, onOpen, preferenceType]);

  return {
    isOpen,
    onClose,
  };
};
