// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

let deferredPrompt;

if (process.browser) {
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });
}

export const PWAInstall = () => {
  deferredPrompt?.prompt();
};
