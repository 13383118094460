// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { useApolloClient } from '@apollo/client';
import {
  Collapse,
  Flex,
  FlexProps,
  forwardRef,
  Spacer,
  TooltipProps,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { Collection, Section } from '~/common/gql';
import { useSectionPreferenceUpdateMutation } from '~/common/gql/section.generated';
import { PageType } from '~/common/utilities/pageInfo';
import { NpCollectionMorph } from '~/components/Icons/NpCollectionMorph';
import { useAppMenu } from '~/containers/common/App/useAppMenu';
import { LeftSidebarButtonOverflow } from '../LeftSidebarButtonOverflow';
import { LeftSidebarSectionButton } from './LeftSidebarSectionButton';
import { LeftSidebarSectionContainer } from './LeftSidebarSectionContainer';
import { LeftSidebarSectionHeader } from './LeftSidebarSectionHeader';
import { LeftSidebarSectionMenu } from './LeftSidebarSectionMenu';

interface Props extends FlexProps {
  section: Pick<Section, 'id' | 'collapsed' | 'title'>;
  collections: Pick<Collection, 'id' | 'sectionId' | 'title' | 'sharingKey'>[];
  tooltipPlacement: TooltipProps['placement'];
}

export const LeftSidebarSection = forwardRef<Props, 'div'>(
  ({ section, collections, tooltipPlacement, ...props }, ref) => {
    const [sectionPreferenceMutation] = useSectionPreferenceUpdateMutation();
    const { isNavItemActive } = useAppMenu();
    const apolloClient = useApolloClient();
    const isOpen = !section.collapsed;

    const onToggleSection = () => {
      const collapse = !section.collapsed;
      sectionPreferenceMutation({
        variables: {
          input: {
            find: { id: section.id },
            collapsed: {
              value: collapse,
            },
          },
        },
      });
      // update the cache immediately
      // don't use optimistic update because the request is not guaranteed to
      // be successful and we want user changes to persist even if the
      // request fails, for example: if the user or server is offline
      apolloClient.cache.modify({
        id: apolloClient.cache.identify(section),
        fields: {
          collapsed: () => collapse,
        },
      });
    };

    return (
      <LeftSidebarSectionContainer>
        <LeftSidebarSectionHeader>
          <Flex as="h3">
            <LeftSidebarSectionButton
              ref={ref}
              isOpen={isOpen}
              onClick={onToggleSection}
            >
              {section.title}
            </LeftSidebarSectionButton>
          </Flex>
          <Spacer />
          <LeftSidebarSectionMenu section={section} />
        </LeftSidebarSectionHeader>
        <Collapse in={isOpen} animateOpacity>
          <Flex direction="column" mt={2}>
            {collections.map((it) => (
              <LeftSidebarButtonOverflow
                key={it.id}
                as={NextLink}
                oid={it.id}
                href={`${PageType.Tag}/${it.id}`}
                leftIcon={<NpCollectionMorph collection={it} />}
                isActive={isNavItemActive(it.id)}
                label={it.title}
                tooltipPlacement={tooltipPlacement}
              >
                {it.title}
              </LeftSidebarButtonOverflow>
            ))}
          </Flex>
        </Collapse>
      </LeftSidebarSectionContainer>
    );
  },
);
