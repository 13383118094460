// Copyright © 2025 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const LeftSidebarSectionHeader = forwardRef<Props, 'div'>(
  ({ ...props }, ref) => {
    return <Flex as="header" ref={ref} {...props} />;
  },
);
