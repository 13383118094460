// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { useEffect, useState } from 'react';
import { useItemSearchLazyQuery } from '~/common/gql/item.generated';
import { ActionType } from '~/common/utilities/usage-event';
import { useUsageEventCreateMutationHelper } from '.';

type QueryProps = Parameters<typeof useItemSearchLazyQuery>[0];

interface Props extends QueryProps {
  searchTerm?: string;
}

const getSearchTermProp = (searchTerm?: string) => ({
  value: searchTerm?.trim() ?? '',
  isProvided: !!searchTerm?.trim().length,
});

export const useItemsSearchQueryPagingHelper = ({
  searchTerm,
  ...props
}: Props) => {
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const [errors, setErrors] = useState();

  const generateOptions = (
    term: string,
    continuationToken: string,
  ): QueryProps => ({
    variables: {
      input: {
        query: getSearchTermProp(term).value,
        continuation: continuationToken,
        limit: 20,
      },
    },
    ssr: false,
    ...props,
  });

  // TODO: replace useLazyQuery with useQuery
  // this is a workaround for a memory leak
  // where changes in the cache triggers an unmounted
  // component to update
  const [querySearch, queryResult] = useItemSearchLazyQuery();

  useEffect(() => {
    if (getSearchTermProp(searchTerm).isProvided) {
      try {
        querySearch(generateOptions(searchTerm, undefined));
        setErrors(undefined);
      } catch (e: any) {
        setErrors(e);
      }
    }
    // TODO: Fix warning appropriately then remove the line below. We only want this function to run once at component initialization.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: { itemSearch: { continuation, hasMore, items } = {} } = {},
    fetchMore,
    refetch,
    variables,
    called,
  } = queryResult;

  // Gets the next page
  const nextPage = async () => {
    if (!continuation) return;

    logUsageEvent({
      actionType: ActionType.LOADING_NEXT,
    });

    try {
      const request = {
        ...generateOptions(variables.input.query, continuation),
        notifyOnNetworkStatusChange: true, // required so that loading state updates and notifies infininte scroll component
      };

      await fetchMore(request);
      setErrors(undefined);
    } catch (e: any) {
      setErrors(e);
    }
  };

  const search = async ({
    searchTerm: _searchTerm,
  }: {
    searchTerm?: string;
  }) => {
    const options = generateOptions(
      getSearchTermProp(_searchTerm).value,
      undefined,
    );

    try {
      if (called) {
        // search term changed, fetch results for new search term
        await refetch(options.variables);
      } else {
        // first time searching
        await querySearch(options);
      }

      setErrors(undefined);
    } catch (e: any) {
      setErrors(e);
    }
  };

  return {
    items,
    ...queryResult,
    searchTerm,
    search,
    nextPage,
    errors,
    hasMore,
  };
};
