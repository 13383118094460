// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { CardBody, CardProps, Flex, forwardRef } from '@chakra-ui/react';

export interface FocusableCardBodyProps extends CardProps {}

export const FocusableCardBody = forwardRef<FocusableCardBodyProps, 'div'>(
  ({ ...props }, ref) => {
    return <CardBody ref={ref} as={Flex} flex="1 1 100%" gap={2} {...props} />;
  },
);
