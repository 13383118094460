// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { QueryResult } from '@apollo/client/react/types/types';
import { ItemByUrlQuery, ItemByUrlQueryVariables } from '~/common/gql';
import { useItemByUrlLazyQuery } from '~/common/gql/item.generated';
import { isUrlHttpOrHttps } from '~/common/utilities/url-utils/isUrlHttpOrHttps';

export const useItemByUrlQueryHelper = () => {
  const [itemByUrlQuery, result] = useItemByUrlLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const getItemByUrl = (url: string) => {
    if (!isUrlHttpOrHttps(url)) return;

    return itemByUrlQuery({
      variables: {
        input: {
          itemUrl: url,
        },
      },
    });
  };

  return [getItemByUrl, result] as [
    (
      url: string,
    ) => Promise<QueryResult<ItemByUrlQuery, ItemByUrlQueryVariables>>,
    ReturnType<typeof useItemByUrlLazyQuery>[1],
  ];
};
