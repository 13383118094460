// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { IfWrap } from '~/components/IfWrap/IfWrap';
import { HintPopoverProps } from './HintPopoverProps';

interface Props
  extends Required<Pick<HintPopoverProps, 'isOpen' | 'onClose'>>,
    Omit<HintPopoverProps, 'isOpen' | 'onClose' | 'children'>,
    PropsWithChildren {}

export const HintPopover: FC<Props> = ({
  hintText,
  dismissText,
  mobileOnly,
  desktopOnly,
  renderInPortal = true,
  ...props
}) => {
  return (
    <Popover
      returnFocusOnClose={false}
      placement="right"
      closeOnBlur={false}
      {...props}
    >
      <PopoverTrigger>{props.children}</PopoverTrigger>
      <IfWrap
        condition={renderInPortal}
        wrapWith={(children) => <Portal>{children}</Portal>}
      >
        <PopoverContent
          w="3xs"
          shadow="md"
          display={
            desktopOnly && !mobileOnly
              ? ['none', 'none', 'flex']
              : mobileOnly
                ? ['flex', 'flex', 'none']
                : 'flex'
          }
        >
          <PopoverArrow />
          <PopoverBody p={6}>
            <Stack spacing={4}>
              <Text fontSize="sm" fontWeight="medium">
                {hintText}
              </Text>
              <Flex>
                <Button variant="npPrimary" size="sm" onClick={props.onClose}>
                  {dismissText ?? 'Maybe later'}
                </Button>
              </Flex>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </IfWrap>
    </Popover>
  );
};
