// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  CenterProps,
  Flex,
  FlexProps,
  forwardRef,
  Heading,
  Image,
  useBoolean,
} from '@chakra-ui/react';

interface Props extends Omit<FlexProps, 'children'> {
  title: string;
  imageSrc: string;
  fallbackHeading: string;
  fallbackBg: CenterProps['bg'];
}

export const WideCardThumbnailCell = forwardRef<Props, 'div'>(
  ({ title, imageSrc, fallbackHeading, fallbackBg, ...props }, ref) => {
    const [imageError, { on: errorOn }] = useBoolean(false);
    return (
      <Flex
        ref={ref}
        flex="0 0"
        minW="3.625rem"
        maxW="3.625rem"
        minH="2.188rem"
        maxH="2.188rem"
        borderRadius="sm"
        overflow="hidden"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        bg={fallbackBg}
        {...props}
      >
        {imageSrc && !imageError && (
          <Image
            w="100%"
            h="100%"
            fit="cover"
            alt={title}
            src={imageSrc}
            onError={errorOn}
            data-dd-privacy="mask"
          />
        )}
        {(!imageSrc || imageError) && (
          <Heading ref={ref} size="sm" color="white" data-dd-privacy="mask">
            {fallbackHeading}
          </Heading>
        )}
      </Flex>
    );
  },
);
