// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { Flex, useTheme } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { mainMenuDesktopWidth } from '~/common/uiTokens';
import { FlexWithQuietScrollBar } from '~/components/FlexWithQuietScrollBar/FlexWithQuietScrollBar';
import { NetworkIndicator } from '~/components/NetworkIndicator';
import { useShortkey } from '~/components/useShortkey';
import { BottomTabBar } from '~/containers/common/App/BottomTabBar/BottomTabBar';
import { LeftSidebar } from '~/containers/common/App/LeftSidebar/LeftSidebar';
import { LeftSidebarDrawer } from '~/containers/common/App/LeftSidebar/LeftSidebarDrawer';
import { MoreDrawer } from '~/containers/common/App/MoreDrawer';
import { useAppContext } from '~/containers/common/AppContext';
import { ModalCollectionCreate } from '~/containers/common/Collection/ModalCollectionCreate/ModalCollectionCreate';
import { ModalCollectionCreateContextProvider } from '~/containers/common/Collection/ModalCollectionCreate/ModalCollectionCreateContextProvider';
import { OnboardingWelcomeMessageModal } from '~/containers/common/OnboardingHint/OnboardingWelcomeMessageModal';
import { SearchContextProvider } from '~/containers/common/Search/SearchContext/SearchContextProvider';
import { SearchPopup } from '~/containers/common/Search/SearchPopup/SearchPopup';
import { ModalSectionCreate } from '~/containers/common/Section/ModalSectionCreate/ModalSectionCreate';
import { ModalSectionCreateContextProvider } from '~/containers/common/Section/ModalSectionCreate/ModalSectionCreateContextProvider';
import { useItemUpdatedSubscriptionHelper } from '~/hooks';
import { BottomSideLayout } from './BottomSideLayout';
import { LeftSideLayout } from './LeftSideLayout';
import { NoticeJavascriptDisabled } from './NoticeJavascriptDisabled';
import { NoticeStack } from './NoticeStack';
import { NoticeUnsupportBrowser } from './NoticeUnsupportBrowser';

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  const { searchModal, mainMenuDrawer, moreDrawer } = useAppContext();

  const { onKeyDownBody } = useShortkey({
    default: 0,
    max: 0,
    handle: {
      '/': () => {
        if (searchModal.isOpen) return;
        searchModal.onOpen();
      },
    },
  });

  useItemUpdatedSubscriptionHelper();

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDownBody);

    return () => {
      document.body.removeEventListener('keydown', onKeyDownBody);
    };
  }, [onKeyDownBody]);

  return (
    <ModalCollectionCreateContextProvider>
      <ModalSectionCreateContextProvider>
        <NetworkIndicator />
        <Flex
          flexDir="column"
          flex="0 0 auto"
          ml={[null, null, mainMenuDesktopWidth]}
        >
          <SearchContextProvider>
            {children}
            <LeftSideLayout>
              <FlexWithQuietScrollBar>
                <LeftSidebar data-side-bar tooltipPlacement="right" />
              </FlexWithQuietScrollBar>
            </LeftSideLayout>
            <BottomSideLayout
              zIndex={
                searchModal.isOpen
                  ? [theme.zIndices.modal + 1, theme.zIndices.modal + 1, 0]
                  : undefined
              }
            >
              <BottomTabBar />
            </BottomSideLayout>
            <LeftSidebarDrawer
              isOpen={mainMenuDrawer.isOpen}
              onClose={mainMenuDrawer.onClose}
            />
            <MoreDrawer
              isOpen={moreDrawer.isOpen}
              onClose={moreDrawer.onClose}
            />
            <SearchPopup
              isOpen={searchModal.isOpen}
              onClose={searchModal.onClose}
            />
          </SearchContextProvider>
          <ModalCollectionCreate />
          <ModalSectionCreate />
          <OnboardingWelcomeMessageModal />
          <NoticeStack>
            <NoticeUnsupportBrowser />
            <noscript>
              <NoticeJavascriptDisabled />
            </noscript>
          </NoticeStack>
        </Flex>
      </ModalSectionCreateContextProvider>
    </ModalCollectionCreateContextProvider>
  );
};
